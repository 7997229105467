import React from 'react';

const boldStyle = { fontWeight: "bold" }

export default {
    description: [
        "Never leave for a walk without poop bags again."
    ],
    bulletPoints: [
        <div>
            <span style={boldStyle}>SECURE VELCRO ATTACHMENT:</span> The durable velcro attachments make it a snap to attach this bag to any leash, harness, waist or running belt.
        </div>,
        <div>
            <span style={boldStyle}>DISPENSE BAGS EASILY:</span> The conveniently placed dispenser is perfect for pulling out bags one by one. It holds your poop bags tightly in place until you need to use another one.
        </div>,
        <div>
            <span style={boldStyle}>HOLDS 2 STANDARD ROLLS OF POOP BAGS:</span> Plus there is room for a few small treats, cash or a light set of keys, making your next outing that much more convenient.
        </div>,
        <div>
            <span style={boldStyle}>REFLECTIVE STRIP:</span> Keeps you and your dog visible on night walks.
        </div>
    ],
    perks: [
        <div>
            <span style={boldStyle}>LIFETIME GUARANTEE:</span> Buy with confidence! This will replace all of those old cheap plastic dispensers that you own, or your money back!
        </div>
    ]
}