import React, { Fragment } from 'react';

const measureInstructions = "Measure your dog's neck with a tape measure at the preferred resting place for the collar. Always allow for adequate breathing room."

const boldStyle = { fontWeight: "bold" }

const sizeDescription = {
    S: `Small: 10" - 12" Long and 1" Wide`,
    M: `Medium: 15" - 17" Long and 1.25" Wide`,
    L: `Large: 18" - 20" Long and 1.5" Wide`
}

export default {
    description: [
        "Your dog's collar is most likely the most frequently used and important piece of gear, and it should be treated as such. It should be durable, versatile, comfortable, safe, made with quality materials and craftsmanship, and not be too heavy. That is exactly how we designed our beautiful collars."

    ],
    bulletPoints: [
        <div><span style={boldStyle}>Comfort</span> - Give your dog the comfort he/she deserves. Each collar is lined with a soft neoprene padding that will protect your dog's neck from irritation when being active. Don't worry about odor or your dog playing in the water: our neoprene dries easily and is odor resistant. The clip is made of heavy-duty plastic so there will be no rusting hardware.
        </div>,

        <div><span style={boldStyle}>Quality</span> - Tearribles Collars intentionally maintain a lightweight design, but are built specifically with heavy duty hardware that is tough enough to resist forces from the most energetic dogs.</div>,

        <div><span style={boldStyle}>Safety</span> - Our collars have light reactive/reflective strips that reflect light and allow your pup to be seen more easily. Stay safe and be seen on your early morning and evening walks, runs, jogs, or hikes with your dog. </div>,

        <div><span style={boldStyle}>Convenience</span> - Hook and loop (aka velcro) strip allows for quick and easy length adjustment, and additional reinforcement.</div>,

        <div><span style={boldStyle}>Design</span> - Choose between our top-selling sleek black or dreamy blue colors.</div>,

        <div><span style={boldStyle}>Lifetime Guarantee</span> - Buy with confidence. Love it or your money back!</div>,
    ],
    sizing: [
        <div>
            {Object.entries(sizeDescription).map(([key, value], index) => (<Fragment key={`collar-size__${index}`}>{value}<br /></Fragment>))}
        </div>,
        measureInstructions
    ],
    perks: null,
    sizeDescription,
}