import React, { useRef } from "react";
import { Link, navigate } from "gatsby";
import { useState, useCallback, useEffect } from "react";
import { useMemo } from "react";
import submitProduct from "../../utils/submit-product";
import { Buffer } from "buffer";
import Img from "gatsby-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPlus } from "@fortawesome/free-solid-svg-icons";

import "./SuggestedItems.scss";
import CTA from "../CTA/cta";
import { useOnOutsideClick } from "../../hooks/useOnClick";

const BuyAllForm = ({ variants, close, ...props }) => {
	const [item0, setItem0] = useState(true);
	const [item1, setItem1] = useState(true);
	const [item2, setItem2] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const ref = useRef();

	useOnOutsideClick(ref, () => close());

	const selectProduct = useCallback(async () => {
		setIsLoading(true);

		const productIds = variants.reduce((acc, item, index) => {
			if (index === 0) {
				return acc;
			}

			if (index === 1 && !item1) {
				return acc;
			}

			if (index === 2 && !item2) {
				return acc;
			}

			acc.push(item.shopifyId);
			return acc;
		}, []);

		for (const productId of productIds) {
			const id = Buffer.from(productId, "utf-8").toString("base64");
			const values = {
				id,
				quantity: 1,
			};
			const bag = {
				props,
				setSubmitting: () => {},
				upsell: true,
			};
			await submitProduct(values, bag);
		}

		if (item0) {
			await props.addToCart();
		} else {
			setIsLoading(false);
			navigate("/cart");
		}
	}, [variants, props.addToCart, item0, item1, item2]);

	const price = variants.reduce((acc, variant, index) => {
		if (index === 0 && !item0) {
			return acc;
		}
		if (index === 1 && !item1) {
			return acc;
		}
		if (index === 2 && !item2) {
			return acc;
		}
		return acc + variant?.presentmentPrices?.[0]?.price?.amount;
	}, 0);

	return (
		<div className="layout__popup">
			<div
				className="layout__popup-card suggested-items__popup-card"
				style={{ maxWidth: "540px" }}
				ref={ref}
			>
				<div className="product-detail__pre-order-confirmation suggested-items__popup">
					<div className="suggested-items__popup-header">
						<div className="suggested-items__pre-order-confirmation__title">
							Frequently Bought Together
						</div>
						<button
							onClick={() => close()}
							className="suggested-items__close"
						>
							<FontAwesomeIcon icon={faClose} />
						</button>
					</div>
					<div className="suggested-items__popup-content">
						{variants.map((variant, index) => {
							const fluid =
								variant.image?.localFile?.childImageSharp
									?.fluid ||
								variant.product?.featuredImage?.localFile
									?.childImageSharp?.fluid;
							if (!fluid) return null;

							const productTitle = variant.product.title;
							const variantTitle = variant.title;

							const name = "item" + index;
							let checked = item0;
							if (index === 1) {
								checked = item1;
							}
							if (index === 2) {
								checked = item2;
							}

							return (
								<label
									htmlFor={name}
									className={`suggested-item__popup-item ${
										checked ? "checked" : ""
									}`}
									key={variant.shopifyId}
								>
									<input
										className="suggested-item__popup-item__checkbox"
										type="checkbox"
										checked={checked}
										onChange={() => {
											if (index === 0) {
												setItem0(!item0);
											} else if (index === 1) {
												setItem1(!item1);
											} else {
												setItem2(!item2);
											}
										}}
										name={name}
										id={name}
									/>
									<Img
										fluid={fluid}
										className="suggested-item__image"
										alt={`Suggested Item - ${variant.title}`}
									/>
									<div className="suggested-item__popup-detail-wrapper">
										<div className="suggested-item__popup-detail">
											<div className="suggested-item__popup-item__title">
												{productTitle}
											</div>
											<div className="suggested-item__popup-item__variant">
												{variantTitle}
											</div>
											<div className="suggested-item__popup-item__price">
												$
												{
													variant
														?.presentmentPrices?.[0]
														?.price.amount
												}
											</div>
										</div>
									</div>
								</label>
							);
						})}
					</div>
					<div className="suggested-items__footer">
						<div className="suggested-items__price">
							Total Price: ${Number.parseFloat(price).toFixed(2)}
						</div>
						<div className="suggested-items__buttons">
							<CTA
								title="Add to Cart"
								onClick={async () => {
									selectProduct();
								}}
								styles={{ marginBottom: 0, marginTop: 0 }}
								loading={isLoading}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export const SuggestedItems = (props) => {
	const [showPopup, setShowPopup] = useState(false);
	const { currentVariant, addToCart, product, data } = props;

	let metafield = product?.metafields?.find((field) => {
		return field.key === "suggested";
	});

	const variantMetafields = currentVariant?.metafields?.find((field) => {
		return field.key === "suggested";
	});

	if (variantMetafields) {
		metafield = variantMetafields;
	}

	const allVariants = data.allShopifyCollection;

	const suggestedItems = useMemo(() => {
		return allVariants?.nodes.reduce((acc, node) => {
			if (!metafield || !metafield.value) return acc;

			for (const product of node.products) {
				for (const variant of product.variants) {
					if (metafield.value.includes(variant.legacyResourceId)) {
						acc.push(variant);
					}
				}
			}
			return acc;
		}, []);
	}, [allVariants, metafield]);

	const displayedVariants = [currentVariant, ...suggestedItems].filter(
		(variant) => {
			return (
				variant?.image?.localFile?.childImageSharp?.fluid ||
				variant?.product?.featuredImage?.localFile?.childImageSharp
					?.fluid
			);
		},
	);

	const numberOfItems = displayedVariants.length;
	const price = displayedVariants.reduce((acc, variant) => {
		return acc + variant?.presentmentPrices?.[0]?.price?.amount;
	}, 0);

	if (!currentVariant || !currentVariant.shopifyId) return null;
	if (!addToCart) return null;

	if (suggestedItems.length === 0) return null;

	return (
		<>
			{showPopup && (
				<BuyAllForm
					{...props}
					variants={displayedVariants}
					close={() => setShowPopup(false)}
				/>
			)}
			<div
				className="product-detail__divider"
				style={{ width: "100%", margin: "24px 0" }}
			/>
			<div className="suggested-items__wrapper">
				<h5 class="product-bullets__heading">
					FREQUENTLY BOUGHT TOGETHER
				</h5>
				<div>
					<div className="suggested-items">
						{displayedVariants.map((variant, index) => {
							const fluid =
								variant.image?.localFile?.childImageSharp
									?.fluid ||
								variant.product?.featuredImage?.localFile
									?.childImageSharp?.fluid;
							if (!fluid) return null;

							// const name = title
							// 	.replace(" - Replacement Parts", " Parts")
							// 	.replace(" - Pre-Order", "")
							// 	.split(" - ")
							// 	.pop()
							// 	.toLowerCase();

							return (
								<React.Fragment key={variant.shopifyId}>
									<Link
										key={variant.shopifyId}
										className="suggested-item"
										to={`/product/${variant.product.handle}`}
									>
										<Img
											fluid={fluid}
											className="suggested-item__image"
											alt={`Suggested Item - ${variant.title}`}
										/>
									</Link>
									{index < numberOfItems - 1 && (
										<div className="suggested-item__plus">
											<FontAwesomeIcon icon={faPlus} />
										</div>
									)}
								</React.Fragment>
							);
						})}
					</div>
				</div>
				<CTA
					secondary
					onClick={() => setShowPopup(!showPopup)}
					title={`Buy All: $${Number.parseFloat(price).toFixed(2)}`}
					suffixIcon="arrow"
					styles={{ width: "100%", marginTop: "0" }}
				>
					Buy All:
				</CTA>
			</div>
		</>
	);
};
