import React from "react";

const boldStyle = { fontWeight: "bold" };

export default {
	description: [
		"Make playtime more rewarding with the newest product from our sister company, Baloomba Treats!",
	],
	secondDescription: [
		"Usage Advice: Ideal for training moments, hide inside interactive toys, or simply showing a little love. Always complement with a balanced diet and ensure fresh water is available.",
		"Feeding Guide: For dogs: Use as a reward during training or as a special snack. Remember, treats should complement a well-balanced diet and not be the primary food source.",
		"Ingredients: Duck, pea flour, pea protein, chickpea, coconut glycerin, potato flour, flaxseed, cane molasses, salt, lactic acid, natural smoke flavor, mixed tocopherols (a natural source of vitamin E)",
		"Guaranteed Analysis: Crude Protein (min.) 13.0%, Crude Fat (min.) 5.0,% Crude Fiber (max.) 3.0%, Moisture (max.) 25.0%.",
		"Calorie Content ME (calculated): 3224 Kcal/kg: 4 Kcal/treat",
		"Packaging & Store Wise: To retain the quacking good taste, store in a cool, dry place and seal securely after each use.",
		"Country of Origin: Made in USA",
	],
	bulletPoints: [
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Decadent duck: </span> Our treats
			encapsulate the robust, meaty flavor of duck that dogs inherently
			love.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Perfectly sized:</span> Baloomba Duck Bites
			fit perfectly into the Tearrible pocket compartments for more
			rewarding playtime, engaging your dog's sense of smell during play.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Training Made Tasty:</span> Sized for
			training sessions, these bites ensure prompt and eager responses,
			paving the way for effective positive reinforcement.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Nature's Best:</span> Wholesome ingredients
			for a well-rounded treat.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Beneficial Bites:</span> Beyond their
			irresistible taste, these morsels offer a good protein source,
			supporting muscle health and overall vitality.
		</p>,
	],
	perks: null,
	sizeDescription: {
		// S: `Small Collar: 10" - 12" Long, 1" Wide.`,
		// M: `Medium Collar: 15" - 17" Long, 1.25" Wide.`,
		// L: `Large Collar: 18" - 20", Long 1.5" Wide.`,
	},
};
