import { useEffect } from "react";
import { useDataLayer } from "./useDataLayer";

const trackCollections = (collectionNames, dataLayer) => {
    // Collections
    Array.isArray(collectionNames) && collectionNames?.forEach((name) => {
        var collections = {
            'productList': name,
            'pageType': 'Collection',
            'event': 'Collection'
        };
        dataLayer.push(collections, 'Collections');
    })
}

const trackProducts = (products, collectionNames, config, dataLayer) => {
    const productsData = {
        products: [],
        ...config,
    }

    if (Array.isArray(products)) {
        products
            .filter((item) => item)
            .forEach((product) => {
                const firstVariant = product?.variants?.find((item) => item.availableForSale);

                const data = {
                    id: product?.legacyResourceId,
                    sku: firstVariant?.sku,
                    variantId: firstVariant?.legacyResourceId,
                    productType: product?.productType,
                    name: product?.title,
                    price: firstVariant?.price,
                    imageURL: product?.featuredImage?.originalSrc,
                    productURL: `https://tearribles.com/product/${product?.handle}`,
                    brand: 'Tearribles',
                    comparePrice: firstVariant?.compareAtPrice,
                    categories: product?.collections?.map((item) => item.title),
                }

                if (Array.isArray(collectionNames)) {
                    data.currentCategory = collectionNames[0]
                }

                if (product.descriptionHtml) {
                    data.description = product.descriptionHtml.replaceAll(/<[^>]*>/g, "");
                }

                productsData.products.push(data);
            })
    }

    if (Array.isArray(productsData?.products) && productsData.products.length > 0) {
        const dataLayerLogName = collectionNames ? 'Collections' : 'Product'
        dataLayer.push(productsData, dataLayerLogName);
    }
}

export const useGTagProducts = (products = [], collectionNames, config = {}) => {

    const { dataLayer } = useDataLayer();

    if (!products) {
        products = []
    }

    // Generate a key for the useEffect based on the products listed.
    const productIds = products?.filter((item) => item)?.map((item) => item?.legacyResourceId);
    const productIdList = Array.isArray(productIds) ? productIds.join(',') : 'empty';

    useEffect(() => {
        trackCollections(collectionNames, dataLayer);
    }, [collectionNames])

    useEffect(() => {
        trackProducts(products, collectionNames, config, dataLayer);
    }, [productIdList])


    const exampleProduct = {
        "id": "64e4fc5e-d624-5fd9-af55-06a053d1102a",
        "title": "Tearrible - Tiny",
        "descriptionHtml": "Perfect for dogs under 10 lbs.",
        "productType": "Tearrible",
        "tags": [
            "Dog Toy",
            "Dogs",
            "Squeaker",
            "Tearribles"
        ],
        "handle": "tearrible-tiny",
        "images": [
            {
                "originalSrc": "https://cdn.shopify.com/s/files/1/0020/0166/6099/products/TTWS-1.2.png?v=1530069821",
                "localFile": {
                    "childImageSharp": {
                        "fluid": {
                            "aspectRatio": 1,
                            "src": "/static/28446cc6fece71e61e8af1f5a47c0dfd/ee604/TTWS-1.2.png",
                            "srcSet": "/static/28446cc6fece71e61e8af1f5a47c0dfd/69585/TTWS-1.2.png 200w,\n/static/28446cc6fece71e61e8af1f5a47c0dfd/497c6/TTWS-1.2.png 400w,\n/static/28446cc6fece71e61e8af1f5a47c0dfd/ee604/TTWS-1.2.png 800w,\n/static/28446cc6fece71e61e8af1f5a47c0dfd/05d05/TTWS-1.2.png 1080w",
                            "srcWebp": "/static/28446cc6fece71e61e8af1f5a47c0dfd/58556/TTWS-1.2.webp",
                            "srcSetWebp": "/static/28446cc6fece71e61e8af1f5a47c0dfd/61e93/TTWS-1.2.webp 200w,\n/static/28446cc6fece71e61e8af1f5a47c0dfd/1f5c5/TTWS-1.2.webp 400w,\n/static/28446cc6fece71e61e8af1f5a47c0dfd/58556/TTWS-1.2.webp 800w,\n/static/28446cc6fece71e61e8af1f5a47c0dfd/54d25/TTWS-1.2.webp 1080w",
                            "sizes": "(max-width: 800px) 100vw, 800px"
                        }
                    }
                }
            },
            {
                "originalSrc": "https://cdn.shopify.com/s/files/1/0020/0166/6099/products/TTWS-1.1.png?v=1530069821",
                "localFile": {
                    "childImageSharp": {
                        "fluid": {
                            "aspectRatio": 1,
                            "src": "/static/308a6126a950382b9964ac68186a7f83/ee604/TTWS-1.1.png",
                            "srcSet": "/static/308a6126a950382b9964ac68186a7f83/69585/TTWS-1.1.png 200w,\n/static/308a6126a950382b9964ac68186a7f83/497c6/TTWS-1.1.png 400w,\n/static/308a6126a950382b9964ac68186a7f83/ee604/TTWS-1.1.png 800w,\n/static/308a6126a950382b9964ac68186a7f83/05d05/TTWS-1.1.png 1080w",
                            "srcWebp": "/static/308a6126a950382b9964ac68186a7f83/58556/TTWS-1.1.webp",
                            "srcSetWebp": "/static/308a6126a950382b9964ac68186a7f83/61e93/TTWS-1.1.webp 200w,\n/static/308a6126a950382b9964ac68186a7f83/1f5c5/TTWS-1.1.webp 400w,\n/static/308a6126a950382b9964ac68186a7f83/58556/TTWS-1.1.webp 800w,\n/static/308a6126a950382b9964ac68186a7f83/54d25/TTWS-1.1.webp 1080w",
                            "sizes": "(max-width: 800px) 100vw, 800px"
                        }
                    }
                }
            }
        ],
        "featuredImage": {
            "originalSrc": "https://cdn.shopify.com/s/files/1/0020/0166/6099/products/TTWS-1.2.png?v=1530069821",
            "localFile": {
                "childImageSharp": {
                    "fluid": {
                        "aspectRatio": 1,
                        "src": "/static/28446cc6fece71e61e8af1f5a47c0dfd/ee604/TTWS-1.2.png",
                        "srcSet": "/static/28446cc6fece71e61e8af1f5a47c0dfd/69585/TTWS-1.2.png 200w,\n/static/28446cc6fece71e61e8af1f5a47c0dfd/497c6/TTWS-1.2.png 400w,\n/static/28446cc6fece71e61e8af1f5a47c0dfd/ee604/TTWS-1.2.png 800w,\n/static/28446cc6fece71e61e8af1f5a47c0dfd/05d05/TTWS-1.2.png 1080w",
                        "srcWebp": "/static/28446cc6fece71e61e8af1f5a47c0dfd/58556/TTWS-1.2.webp",
                        "srcSetWebp": "/static/28446cc6fece71e61e8af1f5a47c0dfd/61e93/TTWS-1.2.webp 200w,\n/static/28446cc6fece71e61e8af1f5a47c0dfd/1f5c5/TTWS-1.2.webp 400w,\n/static/28446cc6fece71e61e8af1f5a47c0dfd/58556/TTWS-1.2.webp 800w,\n/static/28446cc6fece71e61e8af1f5a47c0dfd/54d25/TTWS-1.2.webp 1080w",
                        "sizes": "(max-width: 800px) 100vw, 800px"
                    }
                }
            }
        },
        "variants": [
            {
                "title": "Standard",
                "id": "e2ccca38-b2cc-5a52-8c34-a80e0dba4987",
                "sku": "Tiny",
                "price": "19.99",
                "compareAtPrice": "19.99",
                "availableForSale": true
            },
            {
                "title": "No Squeaker",
                "id": "29751c6c-874c-5e5d-a0fe-5aceff2b78c4",
                "sku": "Tiny - No Squeaker",
                "price": "19.99",
                "compareAtPrice": "19.99",
                "availableForSale": true
            }
        ],
        "size": "tiny"
    }

}