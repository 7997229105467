import React, { Component } from "react";

import Helmet from "react-helmet";
import Container from "../Container/container";
import CTA from "../CTA/cta";

export const EflsightHeader = () => {
	return (
		<Helmet>
			<script
				src="https://apps.elfsight.com/p/platform.js"
				defer
			></script>
		</Helmet>
	);
};

const style = {
	position: "relative",
	padding: "6rem 0",
	marginTop: "0",
	background: "rgb(245, 246, 253)",
	display: "block",
};

export const ElfsightProduct = ({ size }) => {
	const idBySize = {
		xl: "elfsight-app-3a8512fa-c9a3-46fa-bdaa-e4a82e2b18db",
		medium: "elfsight-app-304cb2ab-b1c5-45f0-aa05-cc6863530032",
	};

	if (!size || !idBySize[size]) {
		return null;
	}

	return (
		<>
			<EflsightHeader />
			<div style={style}>
				<Container>
					<div class={idBySize[size]}></div>
				</Container>
			</div>
		</>
	);
};

export const ElfsightMain = () => {
	return (
		<>
			<EflsightHeader />

			<div style={style}>
				<Container>
					<div style={{ marginLeft: "4%", marginRight: "4%" }}>
						<h2>73,935 happy dogs and counting!</h2>
						<h4>What are the other dogs in the park saying?</h4>
					</div>
					<div class="elfsight-app-220d21d7-9745-4310-aa91-157dc6597d2c"></div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							marginTop: "4rem",
						}}
					>
						<CTA to="/products" styles={{ width: "auto" }}>
							Test
						</CTA>
					</div>
				</Container>
			</div>
		</>
	);
};
