import React from 'react';

import plus from '../../assets/plus.svg';
import minus from '../../assets/minus.svg';
import './quantity-select.scss';

const QuantitySelect = ({ name, values, setFieldValue, minValue = 1 }) => {
    
    const handleChange = (event) => {
        if (!event || !event.target) {
            return null;
        }

        const { value } = event.target;
        setFieldValue(name, value);
    }

    const decrementValue = () => {
        const prevValue = parseInt(values[name]);
        if (prevValue <= minValue) return;
        const value = parseInt(values[name]) - 1;

        handleChange({target: { value } })
    }
    const incrementValue = () => handleChange({target: { value: (parseInt(values[name]) + 1) } })

    const decrementClasses = values[name] === 1 || values[name] === '1'
        ? 'quantity-select__icon quantity-select__icon--disabled'
        : 'quantity-select__icon'
    
    return (
        <div className="quantity-select">
            <div className="quantity-select__dec" onClick={decrementValue} >
                <img 
                    className={decrementClasses} 
                    src={minus} 
                    alt="Decrement Quantity"
                />
            </div>
            <input 
                type="number" 
                name={name} 
                value={values[name]}
                onChange={handleChange} 
                className="quantity-select__input"
            />
            <div className="quantity-select__inc" onClick={incrementValue} >
                <img className="quantity-select__icon" src={plus} alt="Increment Quantity"/>
            </div>
        </div>
    )   
}

export default QuantitySelect;