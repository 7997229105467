import React, { Component, useState } from "react";
import Img from "gatsby-image";

import "./image-selector.scss";
import { colorOrder, ImageFixedState } from "../ProductDetail/product-detail";

const ImageSelector = (props) => {
	const [active, setActive] = useState(0);

	const renderSelections = () => {
		return (
			props.images
				// TODO: Filter this based on alt text, not a number.
				.filter((img) => img.altText !== "DO_NOT_DISPLAY")
				.filter((_, index) => index < 9)
				.map((img, index) => {
					let classList = [
						"image-selector__wrapper",
						"image-selector__wrapper--select",
					];

					if (index === props.selectedImage) {
						classList.push("image-selector__wrapper--active");
					}

					const wrapperClass = classList.join(" ");

					const handleSelection = (index, altText) => {
						props.setSelectedImage(index);

						const color = colorOrder.find((item) => {
							return altText?.includes(item);
						});

						let variantByColor = null;

						if (color) {
							variantByColor = props.variants.find((item) => {
								const colorOption = item?.selectedOptions?.find(
									({ name }) => name === "Color"
								);
								return colorOption?.value === color;
							});
						}

						const imageURL = new URL(img.originalSrc);
						const variantByImageSrc = props.variants.find(
							(item) => {
								if (!item?.image?.originalSrc) return false;
								const variantURL = new URL(
									item.image.originalSrc
								);
								if (!imageURL || !variantURL) return false;
								return (
									imageURL.pathname === variantURL.pathname
								);
							}
						);

						const variant = variantByImageSrc || variantByColor;

						if (variant?.id || variant?.shopifyId) {
							const id = variant.shopifyId || variant.id;
							props.setFieldValue("id", id);
						}
					};

					const fluid = img.localFile.childImageSharp.fluid;

					return (
						<button
							className={wrapperClass}
							key={`image-select__${index}`}
							onClick={() => handleSelection(index, img.altText)}
						>
							<div className="image-selector__container">
								<Img
									fluid={fluid}
									className="image-selector__active"
									alt={`${img.altText} - Image ${index + 1}`}
								/>
							</div>
						</button>
					);
				})
		);
	};

	const imageIndex = props.selectedImage || 0;
	const activeImage = props.images[imageIndex];
	const selectorStyle =
		props.fixed === ImageFixedState.FIXED && props.fixedWidth !== 0
			? { width: props.fixedWidth }
			: { width: "100%" };

	const selectorClasses = [`image-selector`];

	if (props.fixed === ImageFixedState.FIXED && !props.isMobile) {
		selectorClasses.push(`image-selector--fixed`);
	}

	return (
		<div
			className={selectorClasses.join(" ")}
			style={selectorStyle}
			ref={props.selectorRef}
		>
			<div className="image-selector__wrapper">
				<div className="image-selector__container">
					{activeImage && (
						<img
							src={
								activeImage.localFile.childImageSharp.fluid.src
							}
							className="image-selector__active"
							alt={props.altText}
						/>
					)}
				</div>
				{props.preOrder && (
					<div className="image-selector__sale">PRE-ORDER</div>
				)}
			</div>
			{props.displayOptions && (
				<div className="image-selector__selections">
					{renderSelections()}
				</div>
			)}
		</div>
	);
};

export default React.forwardRef((props, ref) => (
	<ImageSelector selectorRef={ref} {...props} />
));
