import React, { Component } from "react";

import Helmet from "react-helmet";

const excapeString = (str) => {
	return str?.replace(/"/g, '\\"')?.replace(/\n/g, " ");
};

export const GoogleSEO = ({ product, reviews, size, info }) => {
	const reviewRating = reviews?.data?.[0]?.product?.rating?.average;
	const reviewCount = reviews?.data?.[0]?.product?.rating?.count;

	const productTitle = product?.title || "Tearrible";
	const productImage = product?.featuredImage?.originalSrc;
	const description = info?.sizeDescription?.[size]
		? info?.sizeDescription?.[size]
		: "Dog toy with detachable parts";

	const productPrice = product?.variants?.[0]?.price;

	return (
		<Helmet>
			<script type="application/ld+json">
				{`
{
	"@context": "https://schema.org",
	"@type": "Product",
	"aggregateRating": {
		"@type": "AggregateRating",
		"ratingValue": "${reviewRating}",
		"reviewCount": "${reviewCount}"
	},
	"description": "${excapeString(description)}",
	"name": "${excapeString(productTitle)}",
	"image": "${excapeString(productImage)}",
	"offers": {
		"@type": "Offer",
		"availability": "https://schema.org/InStock",
		"price": "${productPrice}",
		"priceCurrency": "USD"
	},
	"review": [
		${reviews?.data
			?.filter((review) => review?.rating)
			?.filter((_, index) => index < 5)
			?.map((review) => {
				return `{
				"@type": "Review",
				"author": "${review?.customer?.display_name}",
				"datePublished": "${review?.created_at}",
				"reviewBody": "${excapeString(review?.body)}",
				"reviewName": "${excapeString(review?.heading)}",
				"reviewRating": {
					"@type": "Rating",
					"ratingValue": "${review?.rating}"
				}
			}`;
			})
			.join(",")}
	]
}
	`}
			</script>
		</Helmet>
	);
};
