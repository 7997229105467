import React from "react";
import "video-react/dist/video-react.css";

export default {
	"ultimate:small": {
		description: [
			"This combo is the perfect tool to help you spring into Spring time.",
			"Tearrible Tiny provides you & your pup with hours of playtime and bonding, while Collagen Stix give your pup something natural and digestible to chew on when you don't have time to play (or you just want to catch up on the last episode of your favorite show in peace).",
			"The Tearribles reflective leash is light weight, with a padded handle for your comfort and reflective stitching for your safety. The poop bag dispenser fits up to two bags of standard size poop bags, or a poop bag roll and standard size car keys, which makes walking hands free. Baloomba Duck Bites are made with all natural ingredients, are the perfect size to hide inside the Tearrible pockets, and make any hiking reward or training session an extra delicious one.",
			<h5 style={{ marginBottom: "16px" }}>INCLUDED</h5>,
			<ul>
				<li>Tiny Yellow (with Squeaker)</li>
				<li>5 pack of Baloomba 6 inch Thin Collagen Stix</li>
				<li>Tearribles Reflective Dog Leash with padded handle</li>
				<li>Tearribles Poop Bag Dispenser with reflective strip</li>
				<li>5 Oz Bag of delicious Baloomba Duck Bites</li>
			</ul>,
		],
		bulletPoints: [],
		sizeDescription: {},
		secondaryHeadline: {
			"ultimate:small": "Perfect for small dogs!",
		},
	},
	"ultimate:medium": {
		description: [
			"This combo is the perfect tool to help you spring into Spring time.",
			"Tearrible Medium provide you & your pup with hours of playtime and bonding, while Collagen Stix give your pup something natural and digestible to chew on when you don't have time to play (or you just want to catch up on the last episode of your favorite show in peace).",
			"The Tearribles reflective leash is light weight, with a padded handle for your comfort and reflective stitching for your safety. The poop bag dispenser fits up to two bags of standard size poop bags, or a poop bag roll and standard size car keys, which makes walking hands free. Chickie Nugs are 100% natural chicken & oat treats, made with only 3 ingredients and make any hiking reward or training session an extra delicious one.",
			<h5 style={{ marginBottom: "16px" }}>INCLUDED</h5>,
			<ul>
				<li>Tearrible Medium (Baby Blue)</li>
				<li>5 pack of Baloomba 6 inch Medium Collagen Sticks</li>
				<li>Tearribles Reflective Dog Leash with padded handle</li>
				<li>Tearribles Poop Bag Dispenser with reflective strip</li>
				<li>4 Oz Bag of delicious Baloomba Chickie Nugs</li>
			</ul>,
		],
		bulletPoints: [],
		sizeDescription: {},
		secondaryHeadline: {
			"ultimate:medium": "Perfect for medium dogs!",
		},
	},
	"ultimate:xl": {
		description: [
			"This combo is the perfect tool to help you spring into Spring time.",
			"Tearrible XL provide you & your pup with hours of playtime and bonding, while the Baloomba Yak Chew XL will give your pup something natural and digestible to chew on when you don't have time to play (or you just want to catch up on the last episode of your favorite show in peace).",
			"The large Tearribles reflective leash is made with high quality materials, and has two padded handles and reflective stitching for your comfort and safety. The poop bag dispenser fits up to two bags of standard size poop bags, or a poop bag roll and standard size car keys, which makes walking hands free.",
			"The irresistible Baloomba PBJ Delights are made with natural ingredients and make any hiking reward or training session an extra delicious one. They also fit perfectly inside the Tearrible limb pockets for extra rewarding playtime.",
			<h5 style={{ marginBottom: "16px" }}>INCLUDED</h5>,
			<ul>
				<li>Tearrible XL (Royal Blue)</li>
				<li>XL Baloomba Yak Chew (very) long-lasting, all natural</li>
				<li>
					Large Tearribles Reflective Dog Leash with 2 padded handles
					(for heeling and regular walking)
				</li>
				<li>Tearribles Poop Bag Dispenser with reflective strip</li>
				<li>5 Oz Bag of delicious Baloomba PBJ Delights</li>
			</ul>,
		],
		bulletPoints: [],
		sizeDescription: {},
		secondaryHeadline: {
			"ultimate:xl": "Perfect for large dogs!",
		},
	},
};
