import React from "react";

const boldStyle = { fontWeight: "bold" };
const lineItemStyle = {
	marginBottom: "4px",
	marginLeft: "36px",
	// listStyle: "none",
};

export default {
	description: [
		"Introducing Baloomba Bully Sticks - Ethically Sourced and Packed with Goodness!",
		"Next time you need to give your pup a boredom buster, choose the all natural Baloomba Bully Sticks. The perfect way to keep destructive chewing at bay while giving your pup exactly what they want. Bully sticks are medium-strength chew, that can be entirely consumed by an adult medium or large dog in one chew session.",
	],
	secondDescription: [
		<h5
			className="product-bullets__heading"
			style={{ textTransform: "uppercase", marginBottom: "16px" }}
		>
			Guaranteed Analysis:
		</h5>,
		"Elevate your pup's treat experience with Baloomba Bully Sticks – where ethical sourcing meets irresistible flavor and wholesome nutrition.",
		<li style={lineItemStyle}>Crude Protein (min): 65.0%</li>,
		<li style={lineItemStyle}>Crude Fat (min): 2.0%</li>,
		<li style={lineItemStyle}>Crude Fiber (max): 3.0%</li>,
		<li style={lineItemStyle}>Moisture (max): 18.0%</li>,
		<li style={lineItemStyle}>Ash (max): 10.0%</li>,
		<li style={lineItemStyle}>Carbohydrate: NA</li>,
		<li style={{ ...lineItemStyle, marginBottom: "36px" }}>
			Calorie Content (Calculated): 3,500 Kcal/Kg
		</li>,
		<h5
			className="product-bullets__heading"
			style={{ textTransform: "uppercase", marginBottom: "16px" }}
		>
			Important Tips:
		</h5>,
		"Supervision: Always supervise your dog when giving them treats.",
		"Introducing New Treats: If your pet has never had a particular treat before, introduce it gradually (one every three days).",
		"Feeding Recommendation: We recommend a maximum of one bully braid per day once your pet is accustomed to them.",
		"Hydration: Ensure your pet has access to plenty of fresh water at all times.",
		"Hand Hygiene: Wash your hands after handling any chew or treat.",
		"Storage: Store the treats in a dry place at room temperature.",
	],
	bulletPoints: [
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Premium Quality, Ethically Sourced: </span>
			Baloomba Bully Sticks are crafted from 100% South American bull
			pizzle, ethically sourced to ensure the highest standards of quality
			and responsible production. Treat your pup to premium, thoughtfully
			acquired ingredients.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Amino Acid Powerhouse: </span>These premium
			treats are not just delicious – they're a nutritional powerhouse!
			Packed with amino acids, they support brain health, body
			development, and contribute to a luscious coat. Give your dog the
			benefits of a well-rounded treat.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Promotes Dental Health: </span>Baloomba
			Bully Sticks aren't just a tasty chew – they're your dog's dental
			ally. The braided design and natural chewing action help promote
			healthy teeth and gums, reducing plaque and tartar for a dazzling
			smile.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>
				Easy to Digest, High in Protein, Low in Fat:
			</span>{" "}
			We understand the importance of a balanced diet. That's why our
			Bully Sticks are not only easy to digest but also rich in protein
			and low in fat.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Pure Goodness, No Nasties: </span>Say
			goodbye to additives, fillers, hormones, chemicals, and
			preservatives. Baloomba Bully Sticks are crafted with purity in
			mind, ensuring that your pup enjoys a treat free from unnecessary
			extras.
		</p>,
	],
	perks: null,
	sizeDescription: {
		// S: `Small Collar: 10" - 12" Long, 1" Wide.`,
		// M: `Medium Collar: 15" - 17" Long, 1.25" Wide.`,
		// L: `Large Collar: 18" - 20", Long 1.5" Wide.`,
	},
};
