import React, { isValidElement } from "react";

import "./product-bullet-points.scss";
import Container from "../Container/container";
import pawPrint from "../../assets/paw-print.svg";
import pawPrint2 from "../../assets/paw-print2.svg";
import bone from "../../assets/bone.svg";
import bone2 from "../../assets/bone2.svg";
import { ProductTypes } from "../../types";
import { useIsMobile } from "../../utils/use-is-mobile";

export const ANY_OPTION = "ANY_OPTION";

const toyDisclaimer = `
    Every dog plays differently and, since not all toys are created equal,
    it's always best to keep a close watch on your pup in case things get
    ruff. Supervised play will help toys last longer and most importantly
    keep your pal safe. No dog toy is truly indestructible, so always remove
    the toy from playtime if it becomes damaged. Not recommended for dogs
    who have been known to eat inanimate objects.
`;

const removableSqueakerSizes = ["medium", "xl"];

const vipDescription =
	"Squeaker is removable and replaceable by being secured inside a special pocket, preventing it from moving or sliding around the toy during play.";

const vipPerk = "Removable Squeaker";

const renderBulletPoints = (bullet, index, points = "bone") => {
	let bulletNumber = Math.ceil(index % 2);
	let bulletType = bone2;
	switch (bulletNumber) {
		case 1:
			bulletType = bone;
			break;
		case 2:
			bulletType = pawPrint2;
			break;
		case 3:
			bulletType = bone2;
			break;
		default:
			break;
	}

	if (points === "paw") {
		switch (bulletNumber) {
			case 0:
				bulletType = pawPrint;
				break;
			case 1:
				bulletType = pawPrint2;
				break;
			default:
				break;
		}
	}

	if (!bullet) {
		return null;
	}

	return (
		<li
			className="product-bullets__item"
			key={`${points}_${index}_bullet-item`}
		>
			<div className="product-bullets__paw-wrapper">
				<img src={bulletType} className="product-bullets__paw" alt="" />
			</div>
			{bullet}
		</li>
	);
};

export const FeaturesList = ({
	firehose,
	perks = [],
	toy,
	bulletPoints,
	firehoseBulletPoints,
	size,
	preOrderAuthorized,
}) => {
	if (Array.isArray(perks) && perks.length > 0) {
		perks = [...perks];
	}

	// Summer Colors
	if (removableSqueakerSizes.includes(size) && !perks?.includes(vipPerk)) {
		perks?.unshift(vipPerk);
	}

	return (
		<div className="product-bullets__wrapper">
			<section className="product-bullets__info">
				<h5 className="product-bullets__heading">KEY FEATURES</h5>
				<div className="product-bullets__wrapper">
					<div className="product-bullets__points">
						{firehose
							? firehoseBulletPoints.map(renderBulletPoints)
							: bulletPoints.map(renderBulletPoints)}
					</div>
				</div>
				{perks && (
					<>
						{perks && perks.length > 0 && (
							<h5 className="product-bullets__heading">
								COOL PERKS
							</h5>
						)}
						{perks && perks.length > 0 && (
							<div className="product-bullets__wrapper">
								<div className="product-bullets__points">
									{perks.map((item, index) =>
										renderBulletPoints(item, index, "paw")
									)}
								</div>
							</div>
						)}
					</>
				)}
				{toy && (
					<p className="product-bullets__paragraph">
						{toyDisclaimer}
					</p>
				)}
			</section>
		</div>
	);
};

const checkItemMatches = (selectedOptions) => (acc, next) => {
	if (acc) return acc;
	if (!selectedOptions) return false;

	let match = true;

	Object.entries(selectedOptions).forEach(([key, value]) => {
		if (!next[key] || (next[key] !== value && next[key] !== ANY_OPTION)) {
			match = false;
		}
	});

	return match;
};

const firehoseSizes = ["stingray", "octopus", "hammerhead"];

const ProductBulletPoints = ({
	size,
	image,
	additionalInfo,
	info,
	productType,
	selectedOptions,
	sideBar,
	hideTitle = false,
	...props
}) => {
	const { isMobile } = useIsMobile();

	if (!info) {
		return null;
	}

	let {
		bulletPoints,
		firehoseBulletPoints,
		perks,
		sizeDescription,
		description,
		sizing,
	} = info;

	// Return null for mobile sidebar.
	if (sideBar && isMobile) {
		return null;
	}

	if (!size && selectedOptions?.Size) {
		size = selectedOptions.Size;
	}

	// Fire Hose toys
	const firehose = firehoseSizes.includes(size);

	bulletPoints = bulletPoints
		.filter((item) => {
			if (typeof item === "string" || isValidElement(item)) {
				return true;
			}

			if (!Array.isArray(item.exclude) && !Array.isArray(item.include)) {
				return true;
			}

			if (Array.isArray(item.include)) {
				return item.include.reduce(
					checkItemMatches(selectedOptions),
					false
				);
			}

			if (Array.isArray(item.exclude) && item.exclude.includes(size)) {
				return false;
			}

			if (Array.isArray(item.exclude) && size && size.includes(":")) {
				const sizes = size.split(":");
				const comboCheck = sizes.reduce((acc, next) => {
					if (!next) return next;
					return !item.exclude.includes(next);
				}, true);

				if (!comboCheck) {
					return false;
				}
			}

			return true;
		})
		.map((item) => {
			if (typeof item === "string" || isValidElement(item)) {
				return item;
			}

			if (item.point) {
				return item.point;
			}

			return null;
		})
		.filter((item) => item);

	description = description.map((item) => {
		if (typeof item === "string" || isValidElement(item)) {
			return item;
		}

		if (typeof item === "function") {
			return item({
				sizeDescription: sizeDescription[size],
				size,
				allSizeDescriptions: sizeDescription,
			});
		}
	});

	// Summer Colors
	if (
		removableSqueakerSizes.includes(size) &&
		!description.includes(vipDescription)
	) {
		description.splice(2, 0, vipDescription);
	}

	const descriptionHeaders = {
		[ProductTypes.PARTS]: "FULL TOY DESCRIPTION",
		default: "DESCRIPTION",
	};

	const featureList = (
		<FeaturesList
			{...info}
			bulletPoints={bulletPoints}
			firehose={firehose}
			size={size}
			preOrderAuthorized={props.preOrderAuthorized}
		/>
	);

	if (sideBar) {
		return featureList;
	}

	return (
		<div className="product-bullets">
			<Container>
				<div className="product-bullets__wrapper">
					<section className="product-bullets__info">
						{additionalInfo}
						<h5 className="product-bullets__heading">
							{!hideTitle &&
								(descriptionHeaders[productType] ||
									descriptionHeaders.default)}
						</h5>
						{size && sizeDescription?.[size] && !sizing && (
							<p className="product-bullets__paragraph">
								{sizeDescription[size]}
							</p>
						)}
						{description.map((item, index) => {
							if (isValidElement(item)) {
								return (
									<div
										className="product-bullets__paragraph"
										key={`description_${index}`}
									>
										{item}
									</div>
								);
							}
							return (
								<p
									className="product-bullets__paragraph"
									key={`description_${index}`}
								>
									{item}
								</p>
							);
						})}
						{sizing && (
							<h5 className="product-bullets__heading">SIZING</h5>
						)}
						{Array.isArray(sizing) &&
							sizing.map((item, index) => (
								<p
									className="product-bullets__paragraph"
									key={`description_${index}`}
								>
									{item}
								</p>
							))}
					</section>
					{image && (
						<aside className="product-bullets__image-wrapper">
							{image}
						</aside>
					)}
				</div>
			</Container>
			{image && (
				<aside className="product-bullets__image-wrapper--mobile">
					{image}
				</aside>
			)}
			{isMobile && <Container>{featureList}</Container>}
		</div>
	);
};

export default ProductBulletPoints;
