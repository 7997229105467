import React from 'react';
import { ANY_OPTION } from '../../components/ProductBulletPoints/product-bullet-points';
import { ProductTypes } from '../../types';

const boldStyle = { fontWeight: "bold" }

export default {
    type: ProductTypes.LEASH,
    description: [
        "We spared no costs on making any of our products, including our beautiful Tearribles leashes. Designed exactly the way we imagined our ideal leash: high-quality, safe, comfortable, convenient, and beautiful (in no particular order).",
    ],
    bulletPoints: [
        <div><span style={boldStyle}>Quality</span> - Heavy-duty nylon construction, metal hardware.</div>,

        {
            point: <div><span style={boldStyle}>Comfort</span> - Light weight design- especially great for leash training puppies.</div>,
            exclude: ['L']
        },

        {
            point: <div><span style={boldStyle}>Comfort</span> - Two neoprene-padded handles: one at 6 feet, and one at two feet for those times when your doggy needs to walk a bit closer to you.</div>,
            exclude: ['S']
        },

        <div><span style={boldStyle}>Safety</span> - Dual, light-reactive/reflective stitching along the entire leash to keep you and your pup more visible during the night walks.</div>,

        {
            point: <div><span style={boldStyle}>Design</span> - Pick between sleek black or sky blue design top to bottom.</div>,
            include: [{ Size: 'S', Color: ANY_OPTION }]
        },

        {
            point: <div><span style={boldStyle}>Design</span> - Sleek black design from top to bottom, with green accents.</div>,
            include: [{ Size: 'L', Color: 'Black' }]
        },

        {
            point: <div><span style={boldStyle}>Design</span> - Sleek black design from top to bottom, with you dog's favorite toy characters scattered all over it.</div>,
            include: [{ Size: 'L', Color: 'Tearribles' }]
        },

        <div><span style={boldStyle}>Convenience</span> - Metal hoop at the base of the top handle so you can conveniently attach the poop bag dispenser, your keys, or another leash.</div>,

        <div><span style={boldStyle}>LIFETIME GUARANTEE</span> - Buy with confidence. Love it or your money back!</div>,
    ],
    perks: null,
    sizeDescription: {
        S: `Small: 6' Long, 1" Wide.`,
        L: `Large: 6' Long, 5/8" Wide.`
    }
}