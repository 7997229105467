import React from 'react';

const measureInstructions = "Measure your dog's neck with a tape measure at the preferred resting place for the collar. Alway allow for adequate breathing room."

const boldStyle = { fontWeight: "bold" }

export default {
    description: [
        "Designed with durability, safety, and comfort in mind, Tearribles Collar and Leashes are the perfect match for dogs of all shapes and sizes. Ultra-comfy neoprene padding provides your dog with unparalleled comfort, while sturdy nylon exterior, overengineered plastic clip and strong D-ring provide you with the peace of mind that this collar can hold up to whatever your dog can dish out. Reflective strips at the top and bottom of the collar improve your dog's visibility during evening and night walks. Easy and convenient industrial-strength adjustment.",
        `${measureInstructions}`,
        `Nothing will take the joy out of a walk like standing next to a fresh pile of dog poop while trying to open a poop bag for 2 minutes. Tearribles poop bags are super easy to open, so you can spend more time enjoying your walk... and less time fighting bags.`,
        "320 total biodegradable poop bags.",
        <div className="product-bullets__video">
            <div className="product-bullets__video-wrapper">
                <iframe src="https://www.youtube.com/embed/i5zDg26Wtvc" frameBorder="0" allow="encrypted-media;" allowFullScreen></iframe>
            </div>
        </div>
    ],
    bulletPoints: [
        <div><span style={boldStyle}>COLLAR</span> - Padded Tearribles Collar</div>,

        <div><span style={boldStyle}>LEASH</span> - High quality, neoprene-padded Tearribles Leash</div>,

        <div><span style={boldStyle}>POOP BAG HOLDER</span></div>,

        <div><span style={boldStyle}>POOP BAGS</span> - 320 Jasmine-scented, 100% bio-degradable Tearribles poop bags.</div>
    ],
    perks: null,
    sizeDescription: {
        S: `Small Collar: 10" - 12" Long, 1" Wide.`,
        M: `Medium Collar: 15" - 17" Long, 1.25" Wide.`,
        L: `Large Collar: 18" - 20", Long 1.5" Wide.`
    }
}