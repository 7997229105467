import React, { useRef } from "react";
import { graphql } from "gatsby";

import ProductDetail from "../components/ProductDetail/product-detail";
import { SizeSelect } from "../components/SizeSelect/size-select";
import {
	CollarSize,
	CollarColor,
	PoopBagColor,
	HarnessSize,
	GiftCardDenominations,
	GiftCardNote,
	PoopBagBundle,
	LeashSize,
	LeashColor,
	PartSelect,
	PartsColor,
	ChewSize,
	StixSize,
	StixCount,
	TreatSize,
	BullyBraidSize,
	ChristmasTreeSizes,
	ShippingProtectionAmount,
} from "../components/CollarOptions/collar-options";
import Social from "../components/Social/social";
import ProductBulletPoints from "../components/ProductBulletPoints";
import withProductForm from "../hoc/product-form";
import { ProductTypes } from "../types";
import Container from "../components/Container/container";

import * as info from "./lib";
import { GoogleSEO } from "../components/GoogleSEO/googleSEO";
import ComboSelect from "../components/ComboSelect/combo-select";
import Reviews from "../components/Reviews/reviews";
import BoughtTogether from "../components/BoughtTogether/bought-together";
import { useGTagProducts } from "../hooks/useGTagProducts";
import { ElfsightProduct } from "../components/Elfsight/elfsight";
import { FeaturesList } from "../components/ProductBulletPoints/product-bullet-points";

const fiveOzSizes = ["5 oz", "10 oz", "15 oz", "10 Pack"];
const christmasTreeSizes = ["Small", "Medium", "Large"];
const fourOzSizes = ["4 oz", "8 oz", "12 oz"];
const bullyBraidSizes = ["6 Inch- 6 Pack", "12 Inch- 2 Pack"];
const bullyStickSizes = ["6 Inch- 12 Pack", "12 Inch- 6 Pack"];
const beefCheekSizes = [
	"Medium Jumbo Puff- 3 Pack",
	"XL Jumbo Puff- 2 Pack",
	"Medium Jumbo Puff- Single",
	"XL Jumbo Puff- Single",
];
const beefChipSizes = ["1 Cheek Chip", "5-Pack"];

const ProductTemplate = (props) => {
	const domain = process.env.GATSBY_MYSHOPIFY_URL || "default";
	const reviewRef = useRef(null);
	const scrollToReviews = () => {
		reviewRef.current.scrollIntoView({ behavior: "smooth" });
	};

	// Use to set up a seperate size select list.  i.e. Christmas.
	const speicalPaths = [];
	const isSpecial = speicalPaths.find((item) => {
		return props.location.pathname.includes(item);
	});

	let productArray = props.data.allShopifyProduct.edges;

	const product = productArray?.[0]?.node;
	useGTagProducts([product], null, {
		pageType: "Product",
		event: "Product",
	});

	product.images = product?.media
		.map((item) => {
			return item.image;
		})
		.filter((item) => item);

	if (!Array.isArray(productArray)) {
		return null;
	}

	const pageContents = {
		[ProductTypes.PARTS]: {
			content: () => (
				<>
					{/* <ProductBulletPoints size={props.size} info={info.tearribles} toy {...product} /> */}
					{/* <Social hideTitle size={props.size} /> */}
				</>
			),
			options: [
				{
					name: "Parts",
					component: (
						<PartSelect
							selectedOptions={props.selectedOptions}
							setOption={props.setOption}
							options={product.options}
						/>
					),
				},
				{
					name: "Color",
					component: (
						<PartsColor
							selectedOptions={props.selectedOptions}
							setOption={props.setOption}
							options={product.options}
						/>
					),
				},
			],
			info: null,
		},
		[ProductTypes.TEARRIBLE]: {
			content: () => (
				<>
					<ComboSelect size={props.size} />
					<ProductBulletPoints
						size={props.size}
						info={info.tearribles}
						toy
						selectedOptions={props.selectedOptions}
						preOrderAuthorized={props.preOrderAuthorized}
					/>
					{domain === "tearriblesau.myshopify.com" ? (
						<Social hideTitle size={props.size} />
					) : (
						<ElfsightProduct hideTitle size={props.size} />
					)}
					<div ref={reviewRef}>
						<Reviews
							product={product}
							scrollToReviews={scrollToReviews}
							initialData={props?.pageContext?.reviews}
						/>
					</div>
				</>
			),
			options: [
				{
					component: (data, variants, options) => (
						<SizeSelect
							{...data}
							size={props.size}
							isSpecial={isSpecial}
							product={product}
							location={props.location}
							preOrderAuthorized={props.preOrderAuthorized}
							productVariants={variants}
							selectedOptions={props.selectedOptions}
							setOption={props.setOption}
						/>
					),
				},
			],
			info: info.tearribles,
		},
		[ProductTypes.TREE]: {
			content: () => (
				<>
					<ComboSelect size={props.size} />
					<ProductBulletPoints
						size={props.size}
						info={info.tree}
						toy
						selectedOptions={props.selectedOptions}
						preOrderAuthorized={props.preOrderAuthorized}
					/>
					{domain === "tearriblesau.myshopify.com" ? (
						<Social hideTitle size={props.size} />
					) : (
						<ElfsightProduct hideTitle size={props.size} />
					)}
					<div ref={reviewRef}>
						<Reviews
							product={product}
							scrollToReviews={scrollToReviews}
							initialData={props?.pageContext?.reviews}
						/>
					</div>
				</>
			),
			options: [
				{
					name: "Size",
					component: (data, variants, options) => {
						return (
							<TreatSize
								{...data}
								productVariants={variants}
								selectedOptions={props.selectedOptions}
								setOption={props.setOption}
								treatSizes={christmasTreeSizes}
							/>
						);
					},
				},
			],
			info: info.tree,
		},
		[ProductTypes.COMBO]: {
			content: () => (
				<>
					<ProductBulletPoints
						size={props.size}
						selectedOptions={props.selectedOptions}
						info={info.tearribles}
						toy
					/>
					<Social hideTitle size={props.size} />
				</>
			),
			info: info.tearribles,
		},
		[ProductTypes.CHEWPLAY]: {
			content: () => (
				<>
					<ProductBulletPoints
						size={props.size}
						selectedOptions={props.selectedOptions}
						info={info.chewplay}
						toy
					/>
					<div ref={reviewRef}>
						<Reviews
							product={product}
							scrollToReviews={scrollToReviews}
							initialData={props?.pageContext?.reviews}
						/>
					</div>
				</>
			),
			info: info.chewplay,
		},
		[ProductTypes.CHEWPLAYSTICK]: {
			content: () => (
				<>
					<ProductBulletPoints
						size={props.size}
						selectedOptions={props.selectedOptions}
						info={info.chewplaystick}
						toy
					/>
					<div ref={reviewRef}>
						<Reviews
							product={product}
							scrollToReviews={scrollToReviews}
							initialData={props?.pageContext?.reviews}
						/>
					</div>
				</>
			),
			info: info.chewplaystick,
		},
		[ProductTypes.ULTIMATE]: {
			content: () => (
				<>
					<ProductBulletPoints
						size={props.size}
						selectedOptions={props.selectedOptions}
						info={info.ulimate[props.size]}
						toy
					/>
					<div ref={reviewRef}>
						<Reviews
							product={product}
							scrollToReviews={scrollToReviews}
							initialData={props?.pageContext?.reviews}
						/>
					</div>
				</>
			),
			info: info.ulimate[props.size],
		},
		[ProductTypes.SNIFFPLAY]: {
			content: () => (
				<>
					<ProductBulletPoints
						size={props.size}
						selectedOptions={props.selectedOptions}
						info={info.sniffplay}
						toy
					/>
				</>
			),
			info: info.sniffplay,
		},
		[ProductTypes.COLLAR]: {
			content: () => (
				<>
					<BoughtTogether product={product} />
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={info.collars}
					/>
				</>
			),
			options: [
				{
					name: "Size",
					component: (
						<CollarSize
							selectedOptions={props.selectedOptions}
							setOption={props.setOption}
						/>
					),
				},
				{
					name: "Color",
					component: (
						<CollarColor
							selectedOptions={props.selectedOptions}
							setOption={props.setOption}
						/>
					),
				},
			],
			info: info.collars,
		},
		[ProductTypes.HARNESS]: {
			content: () => (
				<>
					<BoughtTogether product={product} />
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={info.harness}
					/>
				</>
			),
			options: [
				{
					name: "Size",
					component: (
						<HarnessSize
							selectedOptions={props.selectedOptions}
							setOption={props.setOption}
						/>
					),
				},
			],
			info: info.harness,
		},
		[ProductTypes.POOP_BAG_HOLDER]: {
			content: () => (
				<>
					<BoughtTogether product={product} />
					<ProductBulletPoints
						info={info.poopBagHolders}
						selectedOptions={props.selectedOptions}
					/>
				</>
			),
			options: [
				{
					name: "Color",
					component: (props, variants) => (
						<PoopBagColor
							selectedOptions={props.selectedOptions}
							setOption={props.setOption}
							variants={variants}
						/>
					),
				},
			],
			info: info.poopBagHolders,
		},
		[ProductTypes.POOP_BAG]: {
			content: () => (
				<>
					<BoughtTogether product={product} />
					<ProductBulletPoints
						info={info.poopBags}
						selectedOptions={props.selectedOptions}
					/>
				</>
			),
			options: [
				{
					name: "Bundle and Save",
					component: (props, variants) => (
						<PoopBagBundle
							selectedOptions={props.selectedOptions}
							setOption={props.setOption}
							variants={variants}
						/>
					),
				},
			],
			info: info.poopBags,
		},
		[ProductTypes.LEASH]: {
			content: () => (
				<>
					<BoughtTogether product={product} />
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={info.leash}
					/>
				</>
			),
			options: [
				{
					name: "Size",
					component: (
						<LeashSize
							selectedOptions={props.selectedOptions}
							setOption={props.setOption}
						/>
					),
				},
				{
					name: "Color",
					component: (
						<LeashColor
							selectedOptions={props.selectedOptions}
							setOption={props.setOption}
						/>
					),
				},
			],
			info: info.leash,
		},
		[ProductTypes.CHEWS]: {
			content: () => (
				<>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={info.chews}
						sideBar={false}
					/>
					<div className="hide-phone">
						<Container>
							<FeaturesList
								bulletPoints={info.chews.bulletPoints}
							/>
						</Container>
					</div>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={{
							...info.chews,
							description: info.chews.secondDescription,
						}}
						sideBar={false}
						hideTitle={true}
					/>
					<div ref={reviewRef}>
						<Reviews
							product={product}
							scrollToReviews={scrollToReviews}
							initialData={props?.pageContext?.reviews}
						/>
					</div>
				</>
			),
			options: [
				{
					name: "Size",
					component: (
						<ChewSize
							selectedOptions={props.selectedOptions}
							setOption={props.setOption}
						/>
					),
				},
			],
			info: info.chews,
		},
		[ProductTypes.PBJ]: {
			content: () => (
				<>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={info.pbj}
						sideBar={false}
					/>
					<div className="hide-phone">
						<Container>
							<FeaturesList
								bulletPoints={info.pbj.bulletPoints}
							/>
						</Container>
					</div>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={{
							...info.pbj,
							description: info.pbj.secondDescription,
						}}
						sideBar={false}
						hideTitle={true}
					/>
					<div ref={reviewRef}>
						<Reviews
							product={product}
							scrollToReviews={scrollToReviews}
							initialData={props?.pageContext?.reviews}
						/>
					</div>
				</>
			),
			options: [
				{
					name: "Size",
					component: (data, variants, options) => {
						return (
							<TreatSize
								{...data}
								productVariants={variants}
								selectedOptions={props.selectedOptions}
								setOption={props.setOption}
								treatSizes={fiveOzSizes}
							/>
						);
					},
				},
			],
			info: info.pbj,
		},
		[ProductTypes.NUGGS]: {
			content: () => (
				<>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={info.nuggs}
						sideBar={false}
					/>
					<div className="hide-phone">
						<Container>
							<FeaturesList
								bulletPoints={info.nuggs.bulletPoints}
							/>
						</Container>
					</div>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={{
							...info.nuggs,
							description: info.nuggs.secondDescription,
						}}
						sideBar={false}
						hideTitle={true}
					/>
					<div ref={reviewRef}>
						<Reviews
							product={product}
							scrollToReviews={scrollToReviews}
							initialData={props?.pageContext?.reviews}
						/>
					</div>
				</>
			),
			options: [
				{
					name: "Size",
					component: (data, variants, options) => {
						return (
							<TreatSize
								{...data}
								productVariants={variants}
								selectedOptions={props.selectedOptions}
								setOption={props.setOption}
								treatSizes={fourOzSizes}
							/>
						);
					},
				},
			],
			info: info.nuggs,
		},
		[ProductTypes.DUCK]: {
			content: () => (
				<>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={info.duck}
						sideBar={false}
					/>
					<div className="hide-phone">
						<Container>
							<FeaturesList
								bulletPoints={info.duck.bulletPoints}
							/>
						</Container>
					</div>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={{
							...info.duck,
							description: info.duck.secondDescription,
						}}
						sideBar={false}
						hideTitle={true}
					/>
					<div ref={reviewRef}>
						<Reviews
							product={product}
							scrollToReviews={scrollToReviews}
							initialData={props?.pageContext?.reviews}
						/>
					</div>
				</>
			),
			options: [
				{
					name: "Size",
					component: (data, variants, options) => {
						return (
							<TreatSize
								{...data}
								productVariants={variants}
								selectedOptions={props.selectedOptions}
								setOption={props.setOption}
								treatSizes={fiveOzSizes}
							/>
						);
					},
				},
			],
			info: info.duck,
		},
		[ProductTypes.BULLY]: {
			content: () => (
				<>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={info.bully}
						sideBar={false}
					/>
					<div className="hide-phone">
						<Container>
							<FeaturesList
								bulletPoints={info.bully.bulletPoints}
							/>
						</Container>
					</div>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={{
							...info.bully,
							description: info.bully.secondDescription,
						}}
						sideBar={false}
						hideTitle={true}
					/>
					<div ref={reviewRef}>
						<Reviews
							product={product}
							scrollToReviews={scrollToReviews}
							initialData={props?.pageContext?.reviews}
						/>
					</div>
				</>
			),
			options: [
				{
					name: "Size",
					component: (data, variants, options) => {
						return (
							<TreatSize
								{...data}
								productVariants={variants}
								selectedOptions={props.selectedOptions}
								setOption={props.setOption}
								treatSizes={bullyBraidSizes}
							/>
						);
					},
				},
			],
			info: info.bully,
		},
		[ProductTypes.BULLY_STICKS]: {
			content: () => (
				<>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={info.bullyStick}
						sideBar={false}
					/>
					<div className="hide-phone">
						<Container>
							<FeaturesList
								bulletPoints={info.bullyStick.bulletPoints}
							/>
						</Container>
					</div>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={{
							...info.bullyStick,
							description: info.bullyStick.secondDescription,
						}}
						sideBar={false}
						hideTitle={true}
					/>
					<div ref={reviewRef}>
						<Reviews
							product={product}
							scrollToReviews={scrollToReviews}
							initialData={props?.pageContext?.reviews}
						/>
					</div>
				</>
			),
			options: [
				{
					name: "Size",
					component: (data, variants, options) => {
						return (
							<TreatSize
								{...data}
								productVariants={variants}
								selectedOptions={props.selectedOptions}
								setOption={props.setOption}
								treatSizes={bullyStickSizes}
							/>
						);
					},
				},
			],
			info: info.bullyStick,
		},
		[ProductTypes.CHEEKS]: {
			content: () => (
				<>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={info.cheeks}
						sideBar={false}
					/>
					<div className="hide-phone">
						<Container>
							<FeaturesList
								bulletPoints={info.cheeks.bulletPoints}
							/>
						</Container>
					</div>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={{
							...info.cheeks,
							description: info.cheeks.secondDescription,
						}}
						sideBar={false}
						hideTitle={true}
					/>
					<div ref={reviewRef}>
						<Reviews
							product={product}
							scrollToReviews={scrollToReviews}
							initialData={props?.pageContext?.reviews}
						/>
					</div>
				</>
			),
			options: [
				{
					name: "Size",
					component: (data, variants, options) => {
						return (
							<TreatSize
								{...data}
								productVariants={variants}
								selectedOptions={props.selectedOptions}
								setOption={props.setOption}
								treatSizes={beefCheekSizes}
								column={true}
							/>
						);
					},
				},
			],
			info: info.cheeks,
		},
		[ProductTypes.CHIPS]: {
			content: () => (
				<>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={info.chips}
						sideBar={false}
					/>
					<div className="hide-phone">
						<Container>
							<FeaturesList
								bulletPoints={info.chips.bulletPoints}
							/>
						</Container>
					</div>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={{
							...info.chips,
							description: info.chips.secondDescription,
						}}
						sideBar={false}
						hideTitle={true}
					/>
					<div ref={reviewRef}>
						<Reviews
							product={product}
							scrollToReviews={scrollToReviews}
							initialData={props?.pageContext?.reviews}
						/>
					</div>
				</>
			),
			options: [
				{
					name: "Size",
					component: (data, variants, options) => {
						return (
							<TreatSize
								{...data}
								productVariants={variants}
								selectedOptions={props.selectedOptions}
								setOption={props.setOption}
								treatSizes={beefChipSizes}
								column={true}
							/>
						);
					},
				},
			],
			info: info.chips,
		},
		[ProductTypes.COLLAGEN]: {
			content: () => (
				<>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={info.collagen}
						sideBar={false}
					/>
					<div className="hide-phone">
						<Container>
							<FeaturesList
								bulletPoints={info.collagen.bulletPoints}
							/>
						</Container>
					</div>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={{
							...info.collagen,
							description: info.collagen.secondDescription,
						}}
						sideBar={false}
						hideTitle={true}
					/>
					<div ref={reviewRef}>
						<Reviews
							product={product}
							scrollToReviews={scrollToReviews}
							initialData={props?.pageContext?.reviews}
						/>
					</div>
				</>
			),
			options: [
				{
					name: "Size",
					component: (
						<StixSize
							selectedOptions={props.selectedOptions}
							setOption={props.setOption}
						/>
					),
				},
				{
					name: "Count",
					component: (
						<StixCount
							selectedOptions={props.selectedOptions}
							setOption={props.setOption}
						/>
					),
				},
			],
			info: info.collagen,
		},
		[ProductTypes.WALKIES]: {
			content: () => (
				<>
					<ProductBulletPoints
						selectedOptions={props.selectedOptions}
						info={info.walkies}
					/>
				</>
			),
			options: [
				{
					name: "Size",
					component: (
						<CollarSize
							selectedOptions={props.selectedOptions}
							setOption={props.setOption}
						/>
					),
				},
				{
					name: "Color",
					component: (
						<CollarColor
							selectedOptions={props.selectedOptions}
							setOption={props.setOption}
						/>
					),
				},
			],
			info: info.walkies,
		},
		[ProductTypes.SHIPPING_PROTECTION]: {
			content: null,
			options: [
				{
					name: null,
					component: <ShippingProtectionAmount {...props} />,
				},
			],
			info: null,
		},
		[ProductTypes.GIFT_CARD]: {
			content: null,
			options: [
				{
					name: "Amount",
					component: (
						<GiftCardDenominations
							selectedOptions={props.selectedOptions}
							setOption={props.setOption}
						/>
					),
				},
				{
					name: "Gift Card Note",
					component: (
						<GiftCardNote
							values={props.values}
							setFieldValue={props.setFieldValue}
						/>
					),
				},
			],
			info: null,
		},
	};

	return (
		<>
			<GoogleSEO
				product={product}
				reviews={props?.pageContext?.reviews}
				size={props.size}
				info={info.tearribles}
			/>
			<ProductDetail
				options={pageContents[props.productType]?.options}
				product={product}
				size={props.size}
				info={pageContents[props.productType]?.info}
				scrollToReviews={scrollToReviews}
				{...props}
			/>
			{typeof pageContents[props.productType]?.content === `function`
				? pageContents[props.productType].content()
				: null}
			{/* <Reviews product={product} /> */}
		</>
	);
};

export default withProductForm(ProductTemplate);

export const productQuery = graphql`
	query productQuery($handle: String) {
		allShopifyProduct(filter: { handle: { eq: $handle } }) {
			edges {
				node {
					id
					createdAt
					legacyResourceId
					shopifyId
					title
					descriptionHtml
					productType
					handle
					tags
					collections {
						title
					}
					metafields {
						key
						value
					}
					media {
						... on ShopifyMediaImage {
							image {
								originalSrc
								localFile {
									childImageSharp {
										fluid {
											...GatsbyImageSharpFluid_withWebp
										}
									}
								}
								altText
							}
						}
					}
					featuredImage {
						originalSrc
						localFile {
							childImageSharp {
								fluid {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
						altText
					}
					options {
						# id
						name
						values
					}
					variants {
						id
						createdAt
						legacyResourceId
						shopifyId
						sku
						price
						presentmentPrices {
							price {
								amount
								currencyCode
							}
						}
						compareAtPrice
						availableForSale
						title
						weight
						weightUnit
						metafields {
							key
							value
						}
						image {
							localFile {
								childImageSharp {
									fluid {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
							originalSrc
							altText
						}
						selectedOptions {
							name
							value
						}
						product {
							title
							featuredImage {
								localFile {
									childImageSharp {
										fluid {
											...GatsbyImageSharpFluid_withWebp
										}
									}
								}
								originalSrc
								altText
							}
						}
					}
				}
			}
		}
		allShopifyCollection(
			filter: { title: { in: ["Tearribles", "Baloomba Hard Chews"] } }
		) {
			nodes {
				products {
					title
					variants {
						id
						shopifyId
						legacyResourceId
						title
						sku
						price
						availableForSale
						presentmentPrices {
							price {
								amount
								currencyCode
							}
						}
						image {
							originalSrc
							localFile {
								childImageSharp {
									fluid {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
							altText
						}
						product {
							title
							handle
							featuredImage {
								localFile {
									childImageSharp {
										fluid {
											...GatsbyImageSharpFluid_withWebp
										}
									}
								}
								originalSrc
								altText
							}
						}
					}
				}
			}
		}
	}
`;
