import React from 'react';

const boldStyle = { fontWeight: "bold" }

export default {
    description: [
        "8 Rolls of 20 bags. 160 total biodegradable poop bags per box.",
        'These Jasmine-scented, high quality, durable poop bags are made with ingredients you can easily find in an average kitchen. Reduce your carbon footprint one poop at a time, and make your walks smell like Spring time all year round. We spared no costs designing and producing our Tearribles poop bags. We absolutely love them, and we are sure you will too.',
        <div className="product-bullets__video">
            <div className="product-bullets__video-wrapper">
                <iframe src="https://www.youtube.com/embed/i5zDg26Wtvc" frameBorder="0" allow="encrypted-media;" allowFullScreen></iframe>
            </div>
        </div>
    ],
    bulletPoints: [
        <div>
            <span style={boldStyle}>EASY OPEN:</span> Nothing will take the joy out of a walk like standing next to a fresh pile of dog poop while trying to open a poop bag for 2 minutes. Tearribles poop bags are super easy to open, so you can spend more time enjoying your walk... and less time fighting bags.
        </div>,
        <div>
            <span style={boldStyle}>EARTH-FRIENDLY:</span> Each box of our poop bags boxes gives you 8 rolls of 100% compostable, bio-degradable poop bags. Every roll is wrapped around a recycled cardboard core, and contains 20 bags, giving you 160 opportunities to reduce your carbon footprint.
        </div>,
        <div>
            <span style={boldStyle}>WON'T LEAK OR TEAR:</span> Our poop bags for dogs are engineered to a durable .02mm thickness. These 100% biodegradable poop bags won't rip or puncture easily.
        </div>,
        <div>
            <span style={boldStyle}>SCENTED:</span> Yes, poop stinks, but picking it up no longer has to! Tearribles poop bags smell like blooming Jasmine.
        </div>,
        <div>
            <span style={boldStyle}>BIG:</span> Each Tearribles poop bag is 13'' tall and 9'' wide , for easy clean up of poops of all sizes.
        </div>,
        <div>
            <span style={boldStyle}>CUTE:</span> Each poop bag features a happy face of Tearrible Tiny.
        </div>,
        <div>
            <span style={boldStyle}>LIFETIME GUARANTEE:</span> Buy with confidence. Love them or your money back!
        </div>,
    ]
}