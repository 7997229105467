import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import arrow from "../../assets/arrow.svg";
import instagramIcon from "../../assets/social-icons/instagram.svg";
import twitterIcon from "../../assets/social-icons/twitter.svg";
import facebookIcon from "../../assets/social-icons/facebook.svg";

import Container from "../../components/Container/container";
import CTA from "../../components/CTA/cta";
import "./social.scss";

const TEARRIBLE_TINY = "tiny";
const TEARRIBLE_MEDIUM = "medium";
const TEARRIBLE_XL = "xl";

class Social extends Component {
	socialProof = [
		{
			type: "Instagram",
			handle: "luna_aka_biggie",
			name: "Luna aka Biggie",
			tearrible: TEARRIBLE_MEDIUM,
			image: "insta.3.post",
			profile: "insta.3.profile",
			content:
				'It’s a new personal record🏆. I have had a toy for 5 days and the squeaker remains intact🐶. Perhaps <div class="social__tearribles-tag">@tearribleinstincts</div> HAS figured me out. I rip this toy’s limbs and tail off, then it miraculously gets put back together and I do it again💚',
		},
		{
			type: "Instagram",
			handle: "lananicolem",
			name: "Lana M",
			tearrible: TEARRIBLE_TINY,
			image: "insta.5.post",
			profile: "insta.5.profile",
			content:
				'Mol loves her tiny <div class="social__tearribles-tag">@tearribleinstincts</div> monster, too❤️ ',
		},
		{
			type: "Instagram",
			handle: "buddhachic13",
			name: "Heidi",
			tearrible: TEARRIBLE_TINY,
			image: "insta.8.post",
			profile: "insta.8.profile",
			content: "Where do you hide your monsters?",
		},
		{
			type: "Twitter",
			handle: "lohrum_ipsum",
			name: "John Lohrum",
			tearrible: TEARRIBLE_MEDIUM,
			image: "twitter.1.post",
			profile: "twitter.1.profile",
			content:
				'<div class="social__tearribles-tag">@tearribles</div> Thought you might appreciate that your toy has become a household favorite.',
		},
		{
			type: "Facebook",
			handle: null,
			name: "Heather T",
			tearrible: TEARRIBLE_XL,
			image: "facebook.2.post",
			profile: "facebook.2.profile",
			content:
				"River is loving her Tearrible! She's been playing with it all night! Though she really takes offense when I try to put him back together 😂",
		},
		{
			type: "Instagram",
			handle: "novaandbo",
			name: "Nova & Bo",
			tearrible: TEARRIBLE_XL,
			image: "insta.2.post",
			profile: "insta.2.profile",
			content:
				'Just got our @kickstarter backed <div class="social__tearribles-tag">#tearribles</div> toy! Let’s see what this baby can do 😍',
		},
		{
			type: "Facebook",
			handle: null,
			name: "Taylor F",
			tearrible: TEARRIBLE_MEDIUM,
			image: "facebook.1.post",
			profile: "facebook.1.profile",
			content:
				"Just got our Tearribles today. Even our old man played with them a short while before getting tired. Thank you!",
		},
		{
			type: "Instagram",
			handle: "tearribleinstincts",
			name: "Tearribles",
			tearrible: TEARRIBLE_TINY,
			image: "insta.9.post",
			profile: "insta.9.profile",
			content:
				"Little Harbour stopped by our booth today with her new BFF Tearrible Tiny 😍",
		},
		{
			type: "Instagram",
			handle: "windsor_westie",
			name: "Windsor_Westie",
			tearrible: TEARRIBLE_XL,
			image: "insta.4.post",
			profile: "insta.4.profile",
			content:
				'Sunday morning #cuddles with my <div class="social__tearribles-tag">@tearribleinstincts</div> best buddy',
		},
		{
			type: "Instagram",
			handle: "tysonmcflyson",
			name: "Tyson McFlyson",
			tearrible: TEARRIBLE_XL,
			image: "insta.6.post",
			profile: "insta.6.profile",
			content:
				'Finally, a toy where all the limbs can be vigorously removed then reattached via velcro. Boo yow! This is one happy #dapperdingo. <div class="social__tearribles-tag">#Tearribles</div> for the win!',
		},
		{
			type: "Instagram",
			handle: "smooth_sb",
			name: "Artie",
			tearrible: TEARRIBLE_XL,
			image: "insta.7.post",
			profile: "insta.7.profile",
			content:
				'Backed a Kickstarter project for an indestructable pupper toy by <div class="social__tearribles-tag">@tearribleinstincts</div> and Artie is over the moon with her new purple friend whose limbs are detachable.',
		},
	];

	constructor(props) {
		super(props);

		const data = this.socialProof
			.filter((item) => {
				if (!this.props.size) {
					return true;
				}

				return item.tearrible === this.props.size;
			})
			.map((item) => {
				const postImage = this.props.data.allFile.edges.find(
					({ node }) => {
						return item.image === node.name;
					}
				);
				const profileImage = this.props.data.allFile.edges.find(
					({ node }) => {
						return item.profile === node.name;
					}
				);

				item.profileImage = profileImage.node.childImageSharp.fluid;
				item.postImage = postImage.node.childImageSharp.fluid;

				return item;
			});

		this.state = {
			scroll: 0,
			mobileScroll: 0,
			data,
		};

		this.incrementScroll = this.incrementScroll.bind(this);
		this.decrementScroll = this.decrementScroll.bind(this);
	}

	incrementScroll() {
		if (this.state.mobileScroll > this.state.data.length - 2) return;
		this.setState({ mobileScroll: this.state.mobileScroll + 1 });

		if (this.state.scroll > this.state.data.length - 4) return;
		this.setState({ scroll: this.state.scroll + 1 });
	}

	decrementScroll() {
		if (!(this.state.scroll < 1)) {
			this.setState({ scroll: this.state.scroll - 1 });
		}
		if (!(this.state.mobileScroll < 1)) {
			this.setState({ mobileScroll: this.state.mobileScroll - 1 });
		}
	}

	renderCards = (dataSet) => {
		return dataSet.map((item) => {
			let newItem = null;
			switch (item.type) {
				case "Instagram":
					newItem = this.renderInstagram(item);
					break;
				case "Twitter":
					newItem = this.renderTwitter(item);
					break;
				case "Facebook":
					newItem = this.renderFacebook(item);
					break;
				default:
					break;
			}

			return newItem;
		});
	};

	renderFacebook = (data) => {
		return (
			<div
				className="social__instagram social__card"
				key={data.handle || data.name}
			>
				<Img
					fluid={data.postImage}
					className="social__instagram-image"
					alt={`${data.handle || data.name} with Tearrible toy`}
				/>
				<div className="social__instagram-info">
					<Img
						fluid={data.profileImage}
						className="social__instagram-profile"
						alt={`${data.handle || data.name} Profile Picture`}
					/>
					<div className="social__instagram-details">
						<p
							className="social__instagram-name"
							style={{ lineHeight: "1rem" }}
						>
							{data.name}
						</p>
					</div>
					<img
						className="social__instagram-icon"
						src={facebookIcon}
						alt="Facebook Icon"
					/>
				</div>
				<div
					className="social__instagram-content"
					dangerouslySetInnerHTML={{ __html: data.content }}
				/>
			</div>
		);
	};

	renderInstagram = (data) => {
		return (
			<div
				className="social__instagram social__card"
				key={data.handle || data.name}
			>
				<Img
					fluid={data.postImage}
					className="social__instagram-image"
					alt={`${data.handle || data.name} with Tearrible toy`}
				/>
				<div className="social__instagram-info">
					<Img
						fluid={data.profileImage}
						className="social__instagram-profile"
						alt={`${data.handle || data.name} Profile Picture`}
					/>
					<div className="social__instagram-details">
						<p className="social__instagram-name">{data.name}</p>
						<p className="social__instagram-handle">
							@{data.handle}
						</p>
					</div>
					<img
						className="social__instagram-icon"
						src={instagramIcon}
						alt="Instagram Icon"
					/>
				</div>
				<div
					className="social__instagram-content"
					dangerouslySetInnerHTML={{ __html: data.content }}
				/>
			</div>
		);
	};

	renderTwitter = (data) => {
		return (
			<div
				className="social__instagram social__card"
				key={data.handle || data.name}
			>
				<Img
					fluid={data.postImage}
					className="social__instagram-image"
					alt={`${data.handle || data.name} with Tearrible toy`}
				/>
				<div className="social__instagram-info">
					<Img
						fluid={data.profileImage}
						className="social__instagram-profile"
						alt={`${data.handle || data.name} Profile Picture`}
					/>
					<div className="social__instagram-details">
						<p className="social__instagram-name">{data.name}</p>
						<p className="social__instagram-handle">
							@{data.handle}
						</p>
					</div>
					<img
						className="social__instagram-icon"
						src={twitterIcon}
						alt="Twitter Icon"
					/>
				</div>
				<div
					className="social__instagram-content"
					dangerouslySetInnerHTML={{ __html: data.content }}
				/>
			</div>
		);
	};

	render() {
		if (!Array.isArray(this.state.data) || this.state.data.length === 0)
			return null;

		const offsetPercent = this.state.scroll * -32;
		const mobileOffset = this.state.mobileScroll * -90 + 5;
		const offsetREM = this.state.scroll;
		const mobileREM = this.state.mobileScroll;
		const left = `calc(${offsetPercent}% - ${offsetREM}rem)`;
		const leftMobile = `calc(${mobileOffset}% - ${mobileREM}rem)`;
		const { hideTitle } = this.props;

		return (
			<div className="social">
				<div className="social__top-background"></div>
				<div className="social__overflow">
					{this.state.scroll !== 0 && (
						<img
							className="social__arrow social__arrow-left social__arrow-desktop"
							src={arrow}
							alt="Scroll Left"
							onClick={() => this.decrementScroll()}
						/>
					)}
					{this.state.scroll !== this.state.data.length - 3 && (
						<img
							className="social__arrow social__arrow-right social__arrow-desktop"
							src={arrow}
							alt="Scroll Right"
							onClick={() => this.incrementScroll()}
						/>
					)}

					{this.state.mobileScroll !== 0 && (
						<img
							className="social__arrow social__arrow-left social__arrow-mobile"
							src={arrow}
							alt="Scroll Left"
							onClick={() => this.decrementScroll()}
						/>
					)}
					{this.state.mobileScroll !== this.state.data.length - 1 && (
						<img
							className="social__arrow social__arrow-right social__arrow-mobile"
							src={arrow}
							alt="Scroll Right"
							onClick={() => this.incrementScroll()}
						/>
					)}
					<Container>
						<div style={{ marginLeft: "4%", marginRight: "4%" }}>
							{!hideTitle && (
								<h2>53,231 happy dogs and counting!</h2>
							)}
							{!hideTitle && (
								<h4>
									What are the other dogs in the park saying?
								</h4>
							)}
						</div>
						<div className="social__wrapper" style={{ left }}>
							{this.renderCards(this.state.data)}
						</div>
						<div
							className="social__wrapper social__wrapper-mobile"
							style={{ left: leftMobile }}
						>
							{this.renderCards(this.state.data)}
						</div>
						{!hideTitle && (
							<div className="social__cta-wrapper">
								<CTA
									to="/products"
									styles={{ width: "auto" }}
								/>
							</div>
						)}
					</Container>
				</div>
			</div>
		);
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query SocialImages {
				allFile(
					filter: { sourceInstanceName: { eq: "social-images" } }
				) {
					edges {
						node {
							name
							childImageSharp {
								fluid {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		`}
	>
		{(data) => <Social {...props} data={data} />}
	</StaticQuery>
);
