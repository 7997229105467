import React from "react";

const boldStyle = { fontWeight: "bold" };

export default {
	description: [
		"A product from our sister company, Baloomba Treats, Baloomba Rawhide-Free Collagen Stix are a fantastic choice for boredom-busting & chewing needs. Crafted from the inner layer of beef skin, these all-natural collagen sticks offer a host of benefits for your beloved pet.",
		"Baloomba 6-Inch Thick Beef Collagen Sticks are crafted from a single sheet of collagen-rich beef skin known as corium. They offer a multitude of benefits for your canine, starting with natural dental care. Furthermore, the easily digestible collagen is converted into essential amino acids, aiding in muscle repair, reducing inflammation, and promoting bone and joint health. Your dog's skin and coat will also benefit, leaving them looking and feeling their best. Baloomba collagen beef sticks are a long-lasting, low-odor chew that is a great way to keep your dog occupied when you don't have time to actively play with them.",
	],
	secondDescription: [
		"Remember, while your doggy enjoys any chews, it's wise to keep a watchful eye. For dogs unaccustomed to these treats, introduce them gradually—one every three days. Once acclimated, limit consumption to one Himalayan Yak Dog Chew Bar per day. Ensure a fresh water supply and practice good hygiene by washing your hands after handling any treat. Store these delights in a cool, dry place at room temperature.",
		"Origin: Sourced from USA Beef, processed in Mexico, and packaged in the USA.",
		"Ingredients: 100% Beef Corium",
	],
	bulletPoints: [
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Rawhide-Free Chews:</span> These chews are
			crafted from the inner layer of beef skin, providing a long-lasting
			and highly digestible alternative to traditional rawhide.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Promotes Healthy Joints and Hips:</span>{" "}
			Natural beef collagen supports your dog's joint health, mobility,
			and flexibility. Additionally, it enhances their skin health and
			leaves their coat looking shiny and vibrant.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Natural and Nutrient-Packed:</span> Our
			collagen sticks are 100% beef, grain-free, and rich in peptides and
			amino acids to help with muscle rebuilding and repair.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Gut Health:</span> These treats contain
			nutrients that combat inflammation and are free from harmful
			additives, hormones, or unhealthy chemicals.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Dental Health:</span> The chewing action
			naturally scrapes away plaque, making them great for dental hygiene
			and a satisfying chew for medium to large-sized dogs at any life
			stage.
		</p>,
	],
	perks: null,
	secondaryHeadline: {
		jumbo: "Great for large dogs.",
		medium: "Great for medium and large dogs who prefer thinner chews.",
		thin: "Great for small and medium dogs who prefer thin chews.",
	},
};
