import React from "react";
import "video-react/dist/video-react.css";

export default {
	description: [
		<h5>TEARRIBLES</h5>,
		"Industrial-strength hook and loop fastener (aka “Velcro”) on limbs provides enough resistance to simulate that of a regularly-stitched toy, and allows your dog to experience the satisfaction of “dismembering their prey” by pulling the limbs off the toy, without actually destroying it.",
		<p>
			Tearribles are interactive toys that will provide you and your dog
			with hours of fun, bonding time, and give your dog the exercise and
			mental stimulation he/she needs to minimize destructive behaviors
			which result from boredom and lack of exercise.{" "}
			<span style={{ fontWeight: "bold" }}>
				Tearribles are not a chew toy.
			</span>
		</p>,
	],
	bulletPoints: [
		"Hook and loop (aka ‘Velcro') pockets allow limbs to be ripped off by the dog, and re-attached by owner, for hours of continuous play",
		"Lined with strong mesh on the inside to provide extra durability and support",
		"Triple-stitching allows toys to be pulled, tugged, and dismembered repeatedly",
		"Perfect for an interactive game of fetch, or tug",
		{
			exclude: ["virus"],
			point: "Squeaker is secured inside of a special pocket, preventing it from sliding around the toy during play",
		},
		"Super soft, high quality plush makes for a great comfort toy, or a cuddle buddy once the play is done",
	],
	sizeDescription: {
		small: (
			<>
				<p>
					Included: 2 Tearrible Tinies, and a bag of delicious
					Baloomba Duck Bites treats you can hide in Tearrible pockets
					for extra rewarding play. This combo is the perfect tool to
					help you turn playtime into a sniffari, where all your dog's
					senses are engaged- including their sense of smell.
				</p>
			</>
		),
		medium: (
			<>
				<p>
					Included: 1 Tearrible XL, 1 Tearrible Medium, and a bag of
					delicious PB&J Baloomba treats you can hide in Tearrible
					pockets for extra rewarding play. This combo is the perfect
					tool to help you turn playtime into a sniffari, where all
					your dog's senses are engaged- including their sense of
					smell.
				</p>
			</>
		),
		xl: (
			<>
				<p>
					Included: 2 Tearrible XLs, and a bag of delicious PB&J
					Baloomba treats you can hide in Tearrible pockets for extra
					rewarding play. This combo is the perfect tool to help you
					turn playtime into a sniffari, where all your dog's senses
					are engaged- including their sense of smell.
				</p>
			</>
		),
	},
	secondaryHeadline: {
		small: "Perfect for small dogs!",
		medium: "Perfect for medium & large dogs!",
		xl: "Perfect for large dogs!",
	},
};
