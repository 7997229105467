import React from "react";

const boldStyle = { fontWeight: "bold" };

export default {
	description: [
		"Training Has Never Been This Delicious!",
		"Introducing our Peanut Butter & Jelly training treats by our sister company, Baloomba Treats. The perfect fusion of two classic flavors that dogs just can't resist! Crafted with all-natural ingredients and a touch of love, these treats promise to make training and play sessions both fun and flavorful.",
		"The irresistible aroma of our PBJ treats makes them a perfect treat to place inside the body pockets of your Medium and larger Tearribles for extra rewarding playtime. ",
		"Authentic Taste: We've captured the creamy richness of real peanut butter and paired it with the sweet tanginess of natural berries, creating a treat your dog will crave.",
	],
	secondDescription: [
		"Feeding Guide: Use as a reward during training, inside treat dispensing puzzles or as a special snack. Remember, treats should complement a well-balanced diet and not be the primary food source.",
		"Freshness First & Storage: For a lasting burst of flavor keep in a cool, dry place. Ensure bag is sealed after each use to maintain freshness.",
		"Ingredients: Oat flour, rice flour, coconut glycerin, cane molasses, ground peanuts,  brewer’s yeast, canola oil, vanilla, beets, strawberries, peanut flavor, strawberry flavor, salt, sorbic acid, citric acid (maintain freshness)",
		"Guaranteed Analysis: Crude Protein (min.) 8.0%, Crude Fat (min.) 4.0,% Crude Fiber (max.) 4.75%, Moisture (max.) 20.0% ",
		"Calorie Content (ME Calculated) 3450 kcal / kg, 16 kcal / treat",
		"Country of Origin: Made in USA",
	],
	bulletPoints: [
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Perfectly Sized:</span> Designed with
			training in mind, our high value treats are perfect for rewarding
			good behavior without overindulging.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>All-Natural Goodness:</span> Just wholesome
			ingredients your dog will love and you can trust.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Packed with Nutrition:</span> Not only are
			they tasty, but they're also packed with protein and essential
			nutrients, ensuring your pup gets the best while they learn.
		</p>,
	],
	perks: null,
	sizeDescription: {
		// S: `Small Collar: 10" - 12" Long, 1" Wide.`,
		// M: `Medium Collar: 15" - 17" Long, 1.25" Wide.`,
		// L: `Large Collar: 18" - 20", Long 1.5" Wide.`,
	},
};
