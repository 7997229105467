import React from "react";

const boldStyle = { fontWeight: "bold" };

export default {
	description: [
		"Discover the Ultimate Long Lasting Chews for Super Chewers!",
		"Introducing Himalayan Cheese Dog Chew Bars—a centuries-old recipe hailing from the Nepalese mountains, expertly designed to satisfy even the most tenacious chewers. Crafted from a blend of authentic yak milk, invigorating salt, and zesty lime juice, these chew bars offer an unrivaled chewing experience that stands the test of time.",
		"Our Himalayan cheese dog chew bars are more than just a treat; they're a challenge designed to keep your powerful chewer engaged for hours on end. Crafted with the finest ingredients and honed by generations of tradition, these bars present a tantalizing puzzle for your furry friend, ensuring lasting entertainment and gratification.",
		"But that's not all—when the final chunks are reached, a mere 20-30 seconds in the microwave works an enchanting transformation. Watch as the chew bar expands into a wholly new, irresistibly crunchy puff, revealing yet another layer of excitement for your pup to savor. Just remember to allow a brief 2-minute cool-down before indulging.",
	],
	secondDescription: [
		"Remember, while your doggy enjoys any chews, it's wise to keep a watchful eye. For dogs unaccustomed to these treats, introduce them gradually—one every three days. Once acclimated, limit consumption to one Himalayan Yak Dog Chew Bar per day. Ensure a fresh water supply and practice good hygiene by washing your hands after handling any treat. Store these delights in a cool, dry place at room temperature.",
		"Unlock a world of lasting entertainment and dental health for your super chewer with our Himalayan Cheese Dog Chew Bars. Order now to treat your pup to the ultimate chewing experience!",
	],
	bulletPoints: [
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Uncompromised Quality:</span> Crafted from
			top-tier yak milk, cow milk, lime juice, and salt—nothing more,
			nothing less. These bars are a natural, grain-free alternative to
			rawhide, free from any harmful chemicals or additives.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Dental Health Bonus:</span> Beyond their
			delectable taste, these Himalayan yak cheese bars play a vital role
			in maintaining dental hygiene. The act of chewing naturally aids in
			plaque and tartar removal, making them an ideal solution for dental
			care.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Time-Tested Craftsmanship:</span> Every bar
			is hand-inspected to guarantee consistent quality and a touch of
			individuality. Variations in size, shape, and color reflect the
			authentic nature of these treats.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Sourced for Excellence:</span> Made at a
			family-owned Yak farm in Nepal, and brought to you by our sister
			company, Baloomba™️, that specializes in healthy, wholesome chews
			and treats.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Ingredients:</span> Yak Milk, Cow Milk,
			Salt, Lime Juice
		</p>,
	],
	perks: null,
	sizeDescription: {
		// S: `Small Collar: 10" - 12" Long, 1" Wide.`,
		// M: `Medium Collar: 15" - 17" Long, 1.25" Wide.`,
		// L: `Large Collar: 18" - 20", Long 1.5" Wide.`,
	},
};
