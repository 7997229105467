import React, { Component } from 'react'
import { StaticQuery, graphql, Link, navigate } from "gatsby"
import Img from 'gatsby-image';

import './bought-together.scss';
import Container from '../Container/container';
import CTA from '../CTA/cta';

const BoughtTogetherItem = ({ item, product }) => {

    // {
    //     "id": "Shopify__Product__Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2NzE0NjM5NDAxNDc=",
    //     "title": "Tearrible Combo: Tiny + Medium",
    //     "descriptionHtml": "Perfect for small and medium dogs!",
    //     "productType": "Combo",
    //     "tags": [
    //         "Combo",
    //         "Dog Toy",
    //         "Dogs",
    //         "Squeaker",
    //         "Tearribles"
    //     ],
    //     "handle": "tearrible-combo-tiny-medium",
    //     "images": [
    //         {
    //             "originalSrc": "https://cdn.shopify.com/s/files/1/0020/0166/6099/products/PhotoRoom_20220211_125017.jpg?v=1644786849",
    //             "localFile": {
    //                 "childImageSharp": {
    //                     "fluid": {
    //                         "aspectRatio": 1,
    //                         "src": "/static/d074028a2379e44bc4359ad573413877/1413f/a5869d894d74c4b809cbb2fa0d3557fc.jpg",
    //                         "srcSet": "/static/d074028a2379e44bc4359ad573413877/f836f/a5869d894d74c4b809cbb2fa0d3557fc.jpg 200w,\n/static/d074028a2379e44bc4359ad573413877/2244e/a5869d894d74c4b809cbb2fa0d3557fc.jpg 400w,\n/static/d074028a2379e44bc4359ad573413877/1413f/a5869d894d74c4b809cbb2fa0d3557fc.jpg 540w",
    //                         "srcWebp": "/static/d074028a2379e44bc4359ad573413877/abd11/a5869d894d74c4b809cbb2fa0d3557fc.webp",
    //                         "srcSetWebp": "/static/d074028a2379e44bc4359ad573413877/61e93/a5869d894d74c4b809cbb2fa0d3557fc.webp 200w,\n/static/d074028a2379e44bc4359ad573413877/1f5c5/a5869d894d74c4b809cbb2fa0d3557fc.webp 400w,\n/static/d074028a2379e44bc4359ad573413877/abd11/a5869d894d74c4b809cbb2fa0d3557fc.webp 540w",
    //                         "sizes": "(max-width: 540px) 100vw, 540px"
    //                     }
    //                 }
    //             }
    //         }
    //     ],
    //     "variants": [
    //         {
    //             "title": "Standard",
    //             "id": "Shopify__ProductVariant__Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5NzEwNzI4NjA2Nw==",
    //             "sku": "Tiny:Medium",
    //             "price": "41.00",
    //             "compareAtPrice": "26.99",
    //             "availableForSale": true
    //         }
    //     ],
    //     "size": "combo"
    // }

    const { variants } = item;
    const { variants: productVariants } = product;

    const compareAtPrice = variants.reduce((acc, variant) => {
        // Multiple compare at prices.
        if (acc && variant.compareAtPrice !== acc) {
            return null;
        }

        // Return lowest compareAtPrice.
        if (variant.compareAtPrice !== variant.price
            && (!acc || acc > variant.compareAtPrice)
        ) {
            return variant.compareAtPrice;
        }

        return acc;
    }, false);

    const variantPrices = variants.reduce((acc, variant) => {

        if (!variant.availableForSale) {
            return acc;
        }

        if (!acc.lowPrice) {
            return {
                lowPrice: variant.price,
                price: variant.price
            }
        }

        if (acc.lowPrice > variant.price) {
            acc.lowPrice = variant.price;
        }

        if (acc.price > variant.price) {
            acc.price = variant.price;
        }

        return acc;
    }, {})

    const productPrices = productVariants.reduce((acc, variant) => {

        if (!variant.availableForSale) {
            return acc;
        }

        if (!acc.lowPrice) {
            return {
                lowPrice: variant.price,
                price: variant.price
            }
        }

        if (acc.lowPrice > variant.price) {
            acc.lowPrice = variant.price;
        }

        if (acc.price > variant.price) {
            acc.price = variant.price;
        }

        return acc;
    }, {})

    const { price } = variantPrices;
    const { price: productPrice } = productPrices;

    const itemImage = item?.featuredImage?.localFile?.childImageSharp?.fluid
    const productImage = product?.featuredImage?.localFile?.childImageSharp?.fluid

    const totalPrice = ((parseFloat(price) * 100) + (parseFloat(productPrice) * 100)) / 100

    const name = item?.title?.replace(' with Reflective Safety Strip', '')

    return <li key={item.id}>
        <Link to={`/product/${item.handle}`} className='BoughtTogetherItem'>
            <div className='BoughtTogetherItem__Images'>
                <div className='BoughtTogetherItem__ImageWrapper'>
                    <div className="BoughtTogetherItem__Container">
                        <Img fluid={productImage} className="BoughtTogetherItem__Image" alt={product.title} />
                    </div>
                </div>
                <span className='BoughtTogetherItem__ImageDivider'>+</span>
                <div className='BoughtTogetherItem__ImageWrapper'>
                    <div className="BoughtTogetherItem__Container">
                        <Img fluid={itemImage} className="BoughtTogetherItem__Image" alt={item.title} />
                    </div>
                </div>
            </div>
            <label>{name}</label>
            <div className='BoughtTogetherItem__Right'>
                Total Price: <span className='BoughtTogetherItem__Price'>${totalPrice} </span>
            </div>
            <CTA title="View Item" onClick={() => navigate(`/product/${item.handle}`)} />
        </Link>
    </li>

}

const BoughtTogether = ({ data, product }) => {

    const gearOptionsByType = {
        leash: ['poop bag', 'collar', 'harness'],
        harness: ['poop bag', 'collar', 'leash'],
        'poop bag': ['leash', 'collar', 'harness'],
        collar: ['leash', 'poop bag', 'harness'],
        'poop bag holder': ['leash', 'poop bag', 'collar'],
    }

    const currentProductType = product?.productType?.toLowerCase();

    const products = data.allShopifyCollection.edges
        .reduce((acc, next) => {
            // Merge all products from each collection into
            // one Array.
            if (!next.node || !next.node.products) {
                return acc;
            }
            return [...acc, ...next.node.products]
        }, [])
        .map((node) => {
            const { productType } = node;
            return { ...node, type: productType.toLowerCase() }
        })
        .filter((node) => {
            return gearOptionsByType[currentProductType].includes(node.type);
        })
        .filter((node) => !node.tags.includes('Hide'))

    if (!Array.isArray(products) || products.length === 0) {
        return null;
    }

    return (
        <div className='BoughtTogether'>
            <Container>
                <h5 className="product-bullets__heading">CUSTOMERS ALSO PURCHASED</h5>
                <div className='BoughtTogether__Products'>
                    {products.map((item) => {
                        return <BoughtTogetherItem item={item} product={product} key={item.id} />
                    })}
                </div>
            </Container>
        </div>
    )

}

export default (props) => (
    <StaticQuery
        query={graphql`
            query boughtTogetherSelectQuery {
                allShopifyCollection(filter: { title: { in: "Gear" } }) {
                    edges {
                        node {
                            title
                            products {
                                id
                                title
                                descriptionHtml
                                productType
                                tags
                                handle
                                # images {
                                #     originalSrc
                                #     localFile {
                                #         childImageSharp {
                                #             fluid {
                                #                 ...GatsbyImageSharpFluid_withWebp_noBase64
                                #             }
                                #         }
                                #     }
                                # }
                                featuredImage {
                                    originalSrc
                                    localFile {
                                        childImageSharp {
                                            fluid {
                                                ...GatsbyImageSharpFluid_withWebp_noBase64
                                            }
                                        }
                                    }
                                }
                                variants {
                                    title
                                    id
                                    sku
                                    price
                                    compareAtPrice
                                    availableForSale
                                }
                            }
                        }
                    }
                }
            }
        `}
    >
        {data => <BoughtTogether data={data} {...props} />}
    </StaticQuery>
);
