import React, { Fragment } from 'react';

const boldStyle = { fontWeight: "bold" }

const sizeDescription = {
    S: `Small: 19" - 22.5" (Chest), 1" Wide.`,
    M: `Medium: 22.5" - 27.5" (Chest), 1" Wide.`,
    L: `Large: 26" - 31.5" (Chest), 1" Wide.`
}

export default {
    description: [
        'We spared no costs on making any of our products, including our no pull harnesses. Made with highest quality materials, exactly the way we imagined our ideal harness: high-quality, safe, comfortable, convenient, and beautiful (in no particular order).'
    ],
    bulletPoints: [
        <div><span style={boldStyle}>Quality</span> - Heavy-duty nylon construction, metal hardware.</div>,

        <div><span style={boldStyle}>Convenience</span> - Two metal clip hooks- one in the front (for no pull training), and one in the back for seatbelting during car rides.</div>,

        <div><span style={boldStyle}>Design</span> - Sleek black design from top to bottom.</div>,

        <div><span style={boldStyle}>Safety</span> - Light-reactive/reflective stitching will help you stay visible to traffic during night walks.</div>,

        <div><span style={boldStyle}>Lifetime Guarantee</span> - Buy with confidence. Love it or your money back!.</div>
    ],
    perks: [
        <div><span style={boldStyle}>Extra Bonus</span> - Each one of our no pull harnesses comes with a high quality seatbelt attachment to keep your doggy safe during car rides</div>
    ],
    sizing: [
        <div>
            {Object.entries(sizeDescription).map(([key, value], index) => (<Fragment key={`harness-size__${index}`}>{value}<br /></Fragment>))}
        </div>
    ],
    sizeDescription,
}