import React from "react";

const boldStyle = { fontWeight: "bold" };
const lineItemStyle = {
	marginBottom: "4px",
	marginLeft: "36px",
	// listStyle: "none",
};

export default {
	description: [
		"Unleash the Goodness with Baloomba Beef Cheek Rolls - Perfect for Medium and Large Chewers!",
	],
	secondDescription: [
		<h5
			className="product-bullets__heading"
			style={{ textTransform: "uppercase", marginBottom: "16px" }}
		>
			Guaranteed Analysis:
		</h5>,
		<li style={lineItemStyle}>Crude Protein (Min) - 80.18%</li>,
		<li style={lineItemStyle}>Crude Fat (Min) - 7.96%</li>,
		<li style={lineItemStyle}>Moisture (Max) - 7.28%</li>,
		<li style={lineItemStyle}>Crude Ash (Max) - 4.58%</li>,
		<li style={lineItemStyle}>Ingredients: Beef Cheek </li>,
		<h5
			className="product-bullets__heading"
			style={{ textTransform: "uppercase", marginBottom: "16px" }}
		>
			Important Tips:
		</h5>,
		"Supervision: Always supervise your dog when giving them treats.",
		"Introducing New Treats: If your pet has never had a particular treat before, introduce it gradually (one every three days).",
		"Feeding Recommendation: We recommend a maximum of one beef cheek roll per day once your pet is accustomed to them.",
		"Hydration: Ensure your pet has access to plenty of fresh water at all times.",
		"Hand Hygiene: Wash your hands after handling any chew or treat.",
		"Storage: Store the treats in a dry place at room temperature.",
	],
	bulletPoints: [
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Healthy Doggy Delight: </span>
			Tailored for medium and large chewers, these rolls are a simple,
			healthy treat that have yet to meet a dog that doesn't like them
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Long-Lasting Canine Entertainment: </span>
			Satisfying and easily digestible, these rolls start with a tough
			crunch and soften as your dog chews, providing lasting enjoyment
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Single Ingredient Wonder: </span>Sourced
			from free-range cattle, with no added chemicals or additives - just
			pure, beefy goodness
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Teeth-Smacking Goodness: </span>Naturally
			stimulates saliva enzymes for better oral care, preventing plaque
			buildup and promoting healthy teeth and gums in your medium or
			large-sized pup
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>All-Natural Awesomeness: </span>As can be
			seen in natural variation in size, color and shape, Baloomba Beef
			Cheek rolls are hand-inspected for quality and contain no
			unnecessary frills- just the good stuff for your canine
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Beef Cheek Slice Rolls Bonanza: </span>
			Made from 100% all-natural, free-range beef - high protein, low fat,
			and a satisfying chew tailored for medium and large-sized dogs
		</p>,
	],
	perks: null,
	sizeDescription: {
		// S: `Small Collar: 10" - 12" Long, 1" Wide.`,
		// M: `Medium Collar: 15" - 17" Long, 1.25" Wide.`,
		// L: `Large Collar: 18" - 20", Long 1.5" Wide.`,
	},
};
