import React, { Component } from 'react'
import { StaticQuery, graphql, Link, navigate } from "gatsby"
import Img from 'gatsby-image';

import './combo-select.scss';
import Container from '../Container/container';
import CTA from '../CTA/cta';

const ComboSelectItem = ({ item }) => {

    // {
    //     "id": "Shopify__Product__Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2NzE0NjM5NDAxNDc=",
    //     "title": "Tearrible Combo: Tiny + Medium",
    //     "descriptionHtml": "Perfect for small and medium dogs!",
    //     "productType": "Combo",
    //     "tags": [
    //         "Combo",
    //         "Dog Toy",
    //         "Dogs",
    //         "Squeaker",
    //         "Tearribles"
    //     ],
    //     "handle": "tearrible-combo-tiny-medium",
    //     "images": [
    //         {
    //             "originalSrc": "https://cdn.shopify.com/s/files/1/0020/0166/6099/products/PhotoRoom_20220211_125017.jpg?v=1644786849",
    //             "localFile": {
    //                 "childImageSharp": {
    //                     "fluid": {
    //                         "aspectRatio": 1,
    //                         "src": "/static/d074028a2379e44bc4359ad573413877/1413f/a5869d894d74c4b809cbb2fa0d3557fc.jpg",
    //                         "srcSet": "/static/d074028a2379e44bc4359ad573413877/f836f/a5869d894d74c4b809cbb2fa0d3557fc.jpg 200w,\n/static/d074028a2379e44bc4359ad573413877/2244e/a5869d894d74c4b809cbb2fa0d3557fc.jpg 400w,\n/static/d074028a2379e44bc4359ad573413877/1413f/a5869d894d74c4b809cbb2fa0d3557fc.jpg 540w",
    //                         "srcWebp": "/static/d074028a2379e44bc4359ad573413877/abd11/a5869d894d74c4b809cbb2fa0d3557fc.webp",
    //                         "srcSetWebp": "/static/d074028a2379e44bc4359ad573413877/61e93/a5869d894d74c4b809cbb2fa0d3557fc.webp 200w,\n/static/d074028a2379e44bc4359ad573413877/1f5c5/a5869d894d74c4b809cbb2fa0d3557fc.webp 400w,\n/static/d074028a2379e44bc4359ad573413877/abd11/a5869d894d74c4b809cbb2fa0d3557fc.webp 540w",
    //                         "sizes": "(max-width: 540px) 100vw, 540px"
    //                     }
    //                 }
    //             }
    //         }
    //     ],
    //     "variants": [
    //         {
    //             "title": "Standard",
    //             "id": "Shopify__ProductVariant__Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5NzEwNzI4NjA2Nw==",
    //             "sku": "Tiny:Medium",
    //             "price": "41.00",
    //             "compareAtPrice": "26.99",
    //             "availableForSale": true
    //         }
    //     ],
    //     "size": "combo"
    // }

    const { variants } = item;

    const compareAtPrice = variants.reduce((acc, variant) => {
        // Multiple compare at prices.
        if (acc && variant.compareAtPrice !== acc) {
            return null;
        }

        // Return lowest compareAtPrice.
        if (variant.compareAtPrice !== variant.price
            && (!acc || acc > variant.compareAtPrice)
        ) {
            return variant.compareAtPrice;
        }

        return acc;
    }, false);

    const variantPrices = variants.reduce((acc, variant) => {

        if (!variant.availableForSale) {
            return acc;
        }

        if (!acc.lowPrice) {
            return {
                lowPrice: variant.price,
                price: variant.price
            }
        }

        if (acc.lowPrice > variant.price) {
            acc.lowPrice = variant.price;
        }

        if (acc.price < variant.price) {
            acc.price = variant.price;
        }

        return acc;
    }, {})

    const { lowPrice, price } = variantPrices;

    const name = item.title.replace('Tearrible Combo: ', '')
    const images = item.images.map((item) => item.localFile.childImageSharp.fluid);

    return <li key={item.id}>
        <Link to={`/product/${item.handle}`} className='ComboSelectItem'>
            <div className='ComboSelectItem__Images'>
                <div className='ComboSelectItem__ImageWrapper'>
                    <div className="ComboSelectItem__Container">
                        <Img fluid={images[1]} className="ComboSelectItem__Image" alt={item.title} />
                    </div>
                </div>
                <span className='ComboSelectItem__ImageDivider'>+</span>
                <div className='ComboSelectItem__ImageWrapper'>
                    <div className="ComboSelectItem__Container">
                        <Img fluid={images[2]} className="ComboSelectItem__Image" alt={item.title} />
                    </div>
                </div>
            </div>
            <label>{name}</label>
            <div className='ComboSelectItem__Right'>
                Get Both: <span className='ComboSelectItem__Price'>${price || compareAtPrice} </span>
                {compareAtPrice && price !== compareAtPrice
                    && <span className="ComboSelectItem__ComparePrice">${compareAtPrice || '$49.99'}</span>
                }
            </div>
            <CTA title="View Combo" onClick={() => navigate(`/product/${item.handle}`)} />
        </Link>
    </li>

}

const ComboSelect = ({ data, size }) => {

    return null;

    const products = data.allShopifyCollection.edges
        .reduce((acc, next) => {
            // Merge all products from each collection into
            // one Array.
            if (!next.node || !next.node.products) {
                return acc;
            }
            return [...acc, ...next.node.products]
        }, [])
        .map((node) => {
            const { handle } = node;
            const sizes = handle
                .split('-')
                .map((item) => {
                    return item.toLowerCase();
                })
                .filter((item) => {
                    return item !== 'tearrible' && item !== 'combo'
                })

            node.sizes = sizes;
            return node;
        })
        .filter((node) => {
            return node.sizes.includes(size);
        })
        .filter((node) => !node.tags.includes('Hide'))
        .reverse() // Reverse to set the correct order once the sold out sort is applied.
        .sort(({ variants, tags }) => {
            // Sort Sold Out items to the end.
            const available = variants.find(({ availableForSale }) => {
                return availableForSale && !tags.includes('Sold Out');
            })

            if (available) {
                return -1
            } else {
                return 1
            }
        })

    if (!Array.isArray(products) || products.length === 0) {
        return null;
    }

    return (
        <div className='ComboSelect'>
            <Container>
                <h5 className="product-bullets__heading">BUNDLE AND SAVE</h5>
                <div className='ComboSelect__Products'>
                    {products.map((item) => {
                        return <ComboSelectItem item={item} key={item.id} />
                    })}
                </div>
            </Container>
        </div>
    )

}

export default (props) => (
    <StaticQuery
        query={graphql`
            query comboSelectQuery {
                allShopifyCollection(filter: { title: { in: "Combos" } }) {
                    edges {
                        node {
                            title
                            products {
                                id
                                title
                                descriptionHtml
                                productType
                                tags
                                handle
                                # images {
                                #     originalSrc
                                #     localFile {
                                #         childImageSharp {
                                #             fluid {
                                #                 ...GatsbyImageSharpFluid_withWebp_noBase64
                                #             }
                                #         }
                                #     }
                                # }
                                featuredImage {
                                    originalSrc
                                    localFile {
                                        childImageSharp {
                                            fluid {
                                                ...GatsbyImageSharpFluid_withWebp_noBase64
                                            }
                                        }
                                    }
                                }
                                variants {
                                    title
                                    id
                                    sku
                                    price
                                    compareAtPrice
                                    availableForSale
                                }
                            }
                        }
                    }
                }
            }
        `}
    >
        {data => <ComboSelect data={data} {...props} />}
    </StaticQuery>
);
