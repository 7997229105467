import React, { Component } from "react";
import { withFormik } from "formik";
import gql from "graphql-tag";

import submitProduct from "../utils/submit-product";

const withProductForm = (WrappedComponent) => {
	class ProductTemplate extends Component {
		constructor(props) {
			super(props);

			const { data } = props;

			// Show the first available product for sale.
			let product = data.allShopifyProduct.edges[0].node.variants.find(
				(variant) => {
					return variant.availableForSale;
				},
			);

			// If not product is availableForSale, then display the first product in
			// the list, but set the product ID to 0 so it can not be added to a cart.
			if (!product) {
				product = Object.assign(
					{},
					data.allShopifyProduct.edges[0].node.variants[0],
				);
				product.id = 0;
			}

			const { id: productId, price, shopifyId, compareAtPrice } = product;
			const {
				id: contentId,
				legacyResourceId,
				title,
				handle,
				productType,
				images,
				featuredImage,
			} = data.allShopifyProduct.edges[0].node;

			let size = null;
			if (handle.includes("tearrible")) {
				const handleElements = handle
					.split("-")
					.filter((item) => item !== "tearrible")
					.filter((item) => item !== "combo");
				size = handleElements.join(":");
			}

			if (handle.includes("chew-play-combo")) {
				const handleElements = handle
					.split("-")
					.filter((item) => item !== "chew")
					.filter((item) => item !== "1")
					.filter((item) => item !== "2")
					.filter((item) => item !== "3")
					.filter((item) => item !== "play")
					.filter((item) => item !== "combo");
				size = handleElements.join(":");
			}

			if (handle.includes("sniff-play-combo")) {
				const handleElements = handle
					.split("-")
					.filter((item) => item !== "sniff")
					.filter((item) => item !== "play")
					.filter((item) => item !== "combo")
					.filter((item) => item !== "1")
					.filter((item) => item !== "2")
					.filter((item) => item !== "3");
				size = handleElements.join(":");
			}

			if (handle.includes("baloomba-collagen-stix")) {
				const handleElements = handle
					.split("-")
					.filter((item) => item !== "baloomba")
					.filter((item) => item !== "collagen")
					.filter((item) => item !== "stix")
					.filter((item) => item !== "10")
					.filter((item) => item !== "5")
					.filter((item) => item !== "ct");
				size = handleElements.join(":");
			}

			if (handle.includes("the-ultimate-combo")) {
				const handleElements = handle
					.split("-")
					.filter((item) => item !== "the")
					.filter((item) => item !== "combo");
				size = handleElements.join(":");
			}

			// Get inital variant option.
			const options = product.selectedOptions.reduce(
				(acc, { name, value }) => {
					acc[name] = value;
					return acc;
				},
				{},
			);

			this.state = {
				productId: shopifyId || productId,
				contentId: legacyResourceId ? legacyResourceId : contentId,
				legacyResourceId,
				price,
				compareAtPrice,
				title,
				size,
				productType,
				images,
				imageIndex: 0,
				featuredImage,
				selectedOptions: options,
			};

			this.setOption = this.setOption.bind(this);
			this.setOptions = this.setOptions.bind(this);
		}

		componentDidMount() {
			let id = null;
			let contentId = null;

			if (this.state.contentId.includes("__")) {
				id = this.state.contentId.split("__").pop();
				contentId = atob(id).split("/").pop();
			} else {
				contentId = this.state.contentId;
			}

			const dynamicProductData = {
				value: this.state.price,
				currency: "USD",
				content_name: this.state.title,
				content_type: "product",
				content_ids: [parseInt(contentId)],
			};

			this.props.setFieldValue("id", this.state.productId);

			// Add dynamicProductData to values to pass into submitProduct function
			// when a product is added to the cart.
			this.props.setFieldValue("dynamicProductData", dynamicProductData);

			// eslint-disable-next-line
			if (
				process.env.NODE_ENV === `production` &&
				typeof fbq === `function`
			) {
				// eslint-disable-next-line
				fbq("track", "ViewContent", dynamicProductData);
			}

			// eslint-disable-next-line
			if (
				process.env.NODE_ENV === `production` &&
				window &&
				window._learnq
			) {
				// eslint-disable-next-line
				const href = window.location.href;
				const url = new URL(href);
				const _learnq = window._learnq || [];
				const product = {
					Name: this.state.title,
					ProductID: parseInt(contentId),
					Categories: this.state.productType,
					ImageURL: this.state.featuredImage?.originalSrc,
					URL: url.href,
					Brand: "Tearribles",
					Price: this.state.price,
					CompareAtPrice: this.state.compareAtPrice,
				};
				_learnq.push(["track", "Viewed Product", product]);

				const recentlyViewedItem = {
					Title: this.state.title,
					ItemId: parseInt(contentId),
					Categories: this.state.productType,
					ImageUrl: this.state.featuredImage?.originalSrc,
					Url: url.href,
					Metadata: {
						Brand: "Tearribles",
						Price: this.state.price,
						CompareAtPrice: this.state.compareAtPrice,
					},
				};
				_learnq.push(["trackViewedItem", recentlyViewedItem]);
			}
		}

		setOption(name, value) {
			const { variants } =
				this.props.data.allShopifyProduct.edges[0].node;

			const newOptions = { ...this.state.selectedOptions, [name]: value };

			this.setState({ selectedOptions: newOptions }, () => {
				const { selectedOptions } = this.state;

				const newVariant = variants.find((variant) => {
					let match = null;
					variant.selectedOptions.forEach((option) => {
						if (match === false) return;

						if (option.value === selectedOptions[option.name]) {
							match = true;
						} else {
							match = false;
						}
					});
					return match;
				});

				if (
					newVariant &&
					newVariant?.image?.originalSrc &&
					this.state.images
				) {
					// Set new image.
					const variantURL = new URL(newVariant?.image?.originalSrc);
					const productIndex = this.state.images.findIndex(
						(image) => {
							const imageURL = new URL(image.originalSrc);
							if (!imageURL || !variantURL) return false;
							return imageURL.pathname === variantURL.pathname;
						},
					);

					if (productIndex !== -1) {
						this.setState({
							...this.state,
							imageIndex: productIndex + 1,
						});
					}
				}

				// Set variant id.
				if (newVariant) {
					this.props.setFieldValue("id", newVariant.shopifyId);
				}
			});
		}

		setOptions(options) {
			const newOptions =
				Array.isArray(options) &&
				options.reduce((acc, { name, value }) => {
					acc[name] = value;
					return acc;
				}, {});

			this.setState({ selectedOptions: newOptions });
		}

		render() {
			const productsQuery = gql`
				query getProductByHandle($handle: String!) {
					productByHandle(handle: $handle) {
						variants(first: 25) {
							edges {
								node {
									id
									inventoryQuantity
									availableForSale
									inventoryItem {
										tracked
									}
								}
							}
						}
					}
				}
			`;

			const { handle } = this.props.data.allShopifyProduct.edges[0].node;

			return (
				<div>
					<WrappedComponent
						{...this.props}
						{...this.state}
						inventoryData={null}
						setOption={this.setOption}
						setOptions={this.setOptions}
					/>
				</div>
			);
		}
	}

	const initalValues = {
		id: 0,
		quantity: 1,
		squeaker: 1,
		dynamicProductData: {},
	};

	return withFormik({
		mapPropsToValues: () => initalValues,
		handleSubmit: submitProduct,
	})(ProductTemplate);
};

export default withProductForm;
