import { useWindowWidth } from "@react-hook/window-size"
import { useEffect, useState } from "react";

export const useIsMobile = () => {

    const [isMobile, setIsMobile] = useState(false);
    const windowWidth = useWindowWidth();

    useEffect(() => {
        if (windowWidth < 600) {
            setIsMobile(true);
        }
    }, [windowWidth]) 

    return { isMobile }

}