import React from "react";
import { Link } from "gatsby";

import "./size-select.scss";

const defaultLinks = {
	tiny: "/product/tearrible-tiny",
	medium: "/product/tearrible-medium",
	xl: "/product/tearrible-xl",
	max: "/product/tearrible-maximus",
	stingray: "/product/tearrible-stingray",
	virus: "/product/tearrible-virus",
	venus: "/product/tearrible-venus-flytrap",
};

const christmasLinks = {
	candycane: "/product/tearrible-candycane",
	gingerbread: "/product/tearrible-gingerbread",
	angel: "/product/tearrible-angel",
};

const activeClassNames = {
	tiny: "size-select__link--active-tiny",
	medium: "size-select__link--active-med",
	xl: "size-select__link--active-xl",
	max: "size-select__link--active-max",
	virus: "size-select__link--active-virus",
	stingray: "size-select__link--active-stingray",
	venus: "size-select__link--active-venus",
};

const activeHalloweenClassName = "size-select__link--active-halloween";
const activeChristmasClassName = "size-select__link--active-christmas";

const squeakerOptions = ["With Squeaker", "Without Squeaker"];

export const SqueakerOption = ({ setOption, selectedOptions }) => {
	if (!selectedOptions || !selectedOptions.Squeaker) return null;

	const currentOption = selectedOptions.Squeaker;

	return (
		<div className="product-detail__size-options" style={{ width: "100%" }}>
			<h4>Options</h4>
			<div className="collar-options collar-options__full-width">
				{squeakerOptions.map((option) => {
					const classes =
						currentOption === option
							? "collar-options__size collar-options__size--active"
							: "collar-options__size";

					return (
						<button
							className={classes}
							key={option}
							data-size={option}
							onClick={() => {
								setOption("Squeaker", option);
							}}
						>
							{option}
						</button>
					);
				})}
			</div>
		</div>
	);
};

const snowballSizes = ["Small", "Medium", "XL"];

export const SnowballSizes = ({
	setOption,
	selectedOptions,
	product,
	productVariants,
	images,
	setSelectedImage,
}) => {
	if (!selectedOptions || !selectedOptions.Size) return null;

	const currentSize = selectedOptions.Size;

	const handleSelection = (selectedID) => {
		const currentVariant = productVariants.find((variant) => {
			return variant.title.includes(selectedID);
		});

		// Change image selected.
		if (selectedID && product?.variants) {
			if (currentVariant?.image?.originalSrc) {
				const currentVariantImageUrl = new URL(
					currentVariant?.image?.originalSrc,
				);

				const imageIndex = images?.findIndex((image) => {
					if (!image.originalSrc) return false;
					const imageUrl = new URL(image.originalSrc);
					return (
						currentVariantImageUrl?.pathname === imageUrl?.pathname
					);
				});

				if (imageIndex !== -1) {
					setSelectedImage(imageIndex);
				}
			}
		}

		setOption("Size", selectedID);
	};

	return (
		<div className="product-detail__size-options" style={{ width: "100%" }}>
			<h4>Size</h4>
			<div className="collar-options collar-options__full-width">
				{snowballSizes.map((size) => {
					const classes =
						currentSize === size
							? "collar-options__size collar-options__size--active"
							: "collar-options__size";

					return (
						<button
							className={classes}
							key={size}
							data-size={size}
							onClick={() => {
								handleSelection(size);
							}}
						>
							{size}
						</button>
					);
				})}
			</div>
		</div>
	);
};

const includeSizes = ["tiny", "medium", "xl"];

const SizeSelect = (props) => {
	const domain = process.env.GATSBY_MYSHOPIFY_URL || "default";

	if (props.size && props.size === "snowball") {
		return (
			<>
				<SqueakerOption {...props} />
				<SnowballSizes {...props} />
			</>
		);
	}

	if (props.size && !includeSizes.includes(props.size)) {
		return null;
	}

	const links = { ...defaultLinks, ...props.links };

	const specialMed =
		props.isSpecial && props.location.pathname.includes("medium");
	const specialXL = props.isSpecial && props.location.pathname.includes("xl");
	const vipMax = props.location.pathname.includes("maximus");
	const vipVirus = props.location.pathname.includes("virus");

	if (props.product.tags.includes("Christmas")) {
		return null;
	}

	// if (props.location && props.location.pathname && props.location.pathname.includes('virus')) {
	//     return <p>Limited Edition Virus. 10" Diameter.</p>
	// }

	const ChristmasLinks = (
		<div className="product-detail__size-options">
			<h4>Size</h4>
			<ul className="size-select">
				<Link
					to={christmasLinks.candycane}
					activeClassName={activeChristmasClassName}
				>
					<li className="size-select__link">TINY</li>
				</Link>
				<Link
					to={christmasLinks.gingerbread}
					activeClassName={activeChristmasClassName}
					className={specialMed ? activeHalloweenClassName : ""}
				>
					<li className="size-select__link">MED</li>
				</Link>
				<Link
					to={christmasLinks.angel}
					activeClassName={activeChristmasClassName}
				>
					<li className="size-select__link">XL</li>
				</Link>
			</ul>
		</div>
	);

	// if (props.isSpecial) {
	//     return ChristmasLinks;
	// }

	if (domain === "tearriblesau.myshopify.com") {
		return (
			<>
				<div className="product-detail__size-options">
					<h4>Size</h4>
					<ul className="size-select">
						<Link
							to={links.tiny}
							activeClassName={activeClassNames.tiny}
						>
							<li className="size-select__link">TINY</li>
						</Link>
						<Link
							to={links.medium}
							activeClassName={activeClassNames.medium}
							className={
								specialMed ? activeHalloweenClassName : ""
							}
						>
							<li className="size-select__link">MED</li>
						</Link>
						<Link
							to={links.xl}
							activeClassName={activeClassNames.xl}
							className={
								specialXL ? activeHalloweenClassName : ""
							}
						>
							<li className="size-select__link">XL</li>
						</Link>
					</ul>
				</div>
			</>
		);
	}

	return (
		<>
			<div className="product-detail__size-options">
				<h4>Size</h4>
				<ul className="size-select">
					<Link
						to={links.tiny}
						activeClassName={activeClassNames.tiny}
					>
						<li className="size-select__link">TINY</li>
					</Link>
					<Link
						to={links.medium}
						activeClassName={activeClassNames.medium}
						className={specialMed ? activeHalloweenClassName : ""}
					>
						<li className="size-select__link">MED</li>
					</Link>
					<Link
						to={links.xl}
						activeClassName={activeClassNames.xl}
						className={specialXL ? activeHalloweenClassName : ""}
					>
						<li className="size-select__link">XL</li>
					</Link>
					{/* <Link
                to={links.venus}
                activeClassName={activeClassNames.venus}
                className={specialXL ? activeHalloweenClassName : ''}
            >
                <li
                    className="size-select__link"
                >Venus</li>
            </Link> */}
					{/* <br className="size-select__break"/> */}
					{/* {<Link
                to={links.max}
                activeClassName={activeClassNames.max}
            >
                <li
                    className="size-select__link"
                >Max</li>
            </Link>} */}
					{/* {<Link
                to={links.max}
                activeClassName={activeClassNames.max}
            >
                <li
                    className="size-select__link"
                >Max</li>
            </Link>} */}
					{/* <Link
						to={links.virus}
						activeClassName={activeClassNames.virus}
					>
						<li className="size-select__link">Virus</li>
					</Link> */}
					{/* <Link
						to={links.stingray}
						activeClassName={activeClassNames.stingray}
					>
						<li className="size-select__link">Stingray</li>
					</Link> */}
				</ul>
			</div>
		</>
	);
};

const HalloweenSpecialEditionSelect = (props) => {
	const { size } = props;
	const links = { ...defaultLinks, ...props.links };

	const halloweenLinks = {
		medium: "/product/tearrible-medium-halloween-special",
		xl: "/product/tearrible-xl-halloween-special",
	};

	return (
		<ul className="size-select">
			<Link to={links[size]} activeClassName={activeClassNames[size]}>
				<li className="size-select__link size-select__link-variation">
					ORIGINAL
				</li>
			</Link>
			<Link
				to={halloweenLinks[size]}
				activeClassName={activeHalloweenClassName}
			>
				<li className="size-select__link size-select__link-variation">
					HALLOWEEN
				</li>
			</Link>
		</ul>
	);
};

export { SizeSelect, HalloweenSpecialEditionSelect };
