import React, { useEffect } from "react";

export const useOnOutsideClick = (ref, callback) => {
	useEffect(() => {
		if (!ref.current) return;

		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				callback(event);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		document.addEventListener("touchstart", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			document.removeEventListener("touchstart", handleClickOutside);
		};
	}, [ref, callback]);
};
