import { Buffer } from 'buffer'
import { useEffect } from 'react';

import { useDataLayer } from "./useDataLayer";

export const useTrackCart = (lineItems, products) => {
    const { dataLayer } = useDataLayer();

    const productMap = (item) => {
        let variant;
        const itemId = Buffer.from(item.variant, 'base64').toString('utf-8');
        const product = products.find((prod) => {
            variant = prod?.node?.variants.find((variant) => {
                return itemId === variant?.shopifyId;
            })
            return variant;
        })

        return {
            id: product?.node?.legacyResourceId,
            sku: variant?.sku,
            variant: variant?.legacyResourceId,
            name: item?.title,
            price: variant?.price,
            quantity: item?.quantity
        };
    }

    useEffect(() => {
        if (!Array.isArray(lineItems) || lineItems.length === 0) {
            return () => null;
        }

        const data = {
            pageType: 'Cart',
            event: 'Cart',
            products: lineItems?.map(productMap)
        }

        dataLayer.push(data, 'Cart');

    }, [lineItems?.map((item) => item.id)])

    const trackAddToCart = (productData) => {
        if (!productData) {
            return null;
        }

        const data = {
            pageType: 'Add to Cart',
            event: 'Add to Cart',
            products: productData
        }

        dataLayer.push(data, 'Add to Cart');
    }

    const trackRemoval = (items) => {
        if (!items) {
            return null;
        }

        if (items && !Array.isArray(items)) {
            items = [items]
        }

        const data = {
            pageType: 'Remove from Cart',
            event: 'Remove from Cart',
            products: lineItems?.map(productMap)
        }

        dataLayer.push(data, 'Remove from Cart');
    }

    return {
        trackRemoval,
        trackAddToCart,
    }

}