import React from "react";

const boldStyle = { fontWeight: "bold" };

export default {
	description: [
		"Meet Chickie Nugs: The Tail-Wagging Goodness",
		"Wholesome Vibes, Wholesome Bites",
		"Chickie Nugs aren't your run-of-the-mill dog treats - they're a tiny celebration of happiness for your furkid. Artfully crafted with love in small batches, these training treats boast a party of flavors featuring real chicken,  chicken gizzards, and oats - that's it! Because your dog deserves the VIP treatment.",
		"No Mystery Ingredients - Just Paw-licking Goodness. We're all about keeping it real. Our Chicken & Oat Training Treats are a treat with no tricks - no fillers, no additives. Just pure goodness that supports your dog's training journey with high-quality lean protein from USA-raised chicken.",
	],
	secondDescription: [],
	bulletPoints: [
		<p style={{ marginBottom: "8px" }}>
			Training is a whole lot more fun with Chickie Nugs! These high value
			treats are pint-sized perfection, making them a breeze to handle and
			a snap to reward during those teachable moments. Whether it\'s
			"sit," "stay," or a high-five, Chickie Nugs turn every trick into a
			treat.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			We're all about your dog's happiness, inside and out. That's why
			we've tossed in oats, the secret ingredient for a happy tummy.
			Chickie Nugs not only taste like a party but also keep your pup's
			digestion on the right track. Because a happy puppy starts from the
			inside!
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>No Mystery Ingredients:</span> Just
			Paw-licking Goodness. We're all about keeping it real. Our Chicken &
			Oat Training Treats are a treat with no tricks - no fillers, no
			additives. Just pure goodness that supports your dog's training
			journey with high-quality lean protein from USA-raised chicken.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			<span style={boldStyle}>Nutrient Fiesta for a Top Dog:</span>
			Chicken brings the health benefits to the party, promoting muscle
			health and a fab physique. Rolled oats add the fiber flair, keeping
			things moving smoothly in the digestion department and giving your
			dog the energy to keep the good times rolling.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			Made in the USA - Chickie Nugs are made with chicken born and raised
			in the USA. Quality and transparency are our BFFs, and we wouldn't
			give your dog anything we don't feed ours, so you can trust that
			your dog's treat game is top-notch.
		</p>,
		<p style={{ marginBottom: "8px" }}>
			Add to cart and get your pup ready for a treat that's not just tasty
			but a downright blast! Chickie Nugs - where every moment with your
			dog is a pawty waiting to happen.
		</p>,
	],
	perks: null,
	sizeDescription: {
		// S: `Small Collar: 10" - 12" Long, 1" Wide.`,
		// M: `Medium Collar: 15" - 17" Long, 1.25" Wide.`,
		// L: `Large Collar: 18" - 20", Long 1.5" Wide.`,
	},
};
