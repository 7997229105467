import React from "react";
import "video-react/dist/video-react.css";

export default {
	description: [
		<h5>TEARRIBLES</h5>,
		"Industrial-strength hook and loop fastener (aka “Velcro”) on limbs provides enough resistance to simulate that of a regularly-stitched toy, and allows your dog to experience the satisfaction of “dismembering their prey” by pulling the limbs off the toy, without actually destroying it.",
		<p>
			Tearribles are interactive toys that will provide you and your dog
			with hours of fun, bonding time, and give your dog the exercise and
			mental stimulation he/she needs to minimize destructive behaviors
			which result from boredom and lack of exercise.{" "}
			<span style={{ fontWeight: "bold" }}>
				Tearribles are not a chew toy.
			</span>
		</p>,
		<h5>CHEWS</h5>,
		"Baloomba Bully Sticks are crafted from 100% South American bull pizzle. The perfect way to keep destructive chewing at bay while giving your pup exactly what they want. Bully sticks are medium-strength chew, that can be entirely consumed by an adult medium or large dog in one chew session.",
	],
	bulletPoints: [
		"Hook and loop (aka ‘Velcro') pockets allow limbs to be ripped off by the dog, and re-attached by owner, for hours of continuous play",
		"Lined with strong mesh on the inside to provide extra durability and support",
		"Triple-stitching allows toys to be pulled, tugged, and dismembered repeatedly",
		"Perfect for an interactive game of fetch, or tug",
		{
			exclude: ["virus"],
			point: "Squeaker is secured inside of a special pocket, preventing it from sliding around the toy during play",
		},
		"Super soft, high quality plush makes for a great comfort toy, or a cuddle buddy once the play is done",
	],
	sizeDescription: {
		smedium: (
			<>
				<p>
					Included: Tearrible Virus and Tearrible Medium, and a 12
					pack of Baloomba 6 Inch Bully Sticks. This combo is the
					perfect tool to help you turn your "super chewer" into a dog
					that knows the difference between playtime and downtime.
				</p>
				<p>
					Tearribles provide you with hours of playtime with your pup,
					while Bully Stix give your pup something natural and
					digestible to chew on when you don't have time to play (or
					you just want to catch up on the last episode of your
					favorite show in peace).
				</p>
			</>
		),
		medium: (
			<>
				<p>
					Included: Tearrible Virus and Tearrible Medium, and a 12
					pack of Baloomba 6 Inch Bully Sticks. This combo is the
					perfect tool to help you turn your "super chewer" into a dog
					that knows the difference between playtime and downtime.
				</p>
				<p>
					Tearribles provide you with hours of playtime with your pup,
					while Bully Stix give your pup something natural and
					digestible to chew on when you don't have time to play (or
					you just want to catch up on the last episode of your
					favorite show in peace).
				</p>
			</>
		),
		xl: (
			<>
				<p>
					Included: Terrible XL + Tearrible Virus and a 6 pack of
					Baloomba 12 inch Bully Sticks. This combo is the perfect
					tool to help you turn your "super chewer" into a dog that
					knows the difference between playtime and downtime.
				</p>
				<p>
					Tearribles provide you with hours of playtime with your dog,
					while Bully sticks give him something natural and digestible
					to chew on when you don't have time to play (or you just
					want to catch up on the last episode of your favorite show
					in peace).
				</p>
			</>
		),
	},
	secondaryHeadline: {
		smedium: "Perfect for medium or small dogs who prefer thinner chews!",
		medium: "Perfect for medium dogs!",
		xl: "Perfect for large dogs!",
	},
};
