import React from "react";

export default {
	description: [
		<p style={{ fontWeight: 600, marginTop: "1.6rem" }}>
			"Look mom! No stuffing!"
		</p>,
		'Meet the superstar of our Christmas Tearrors line up, and the first Tearrible that has no stuffing in the main body of the toy. Offered in 3 sizes (7", 12", and 16") for a destroyer of every size, the Tearrible Christmas Tree has 8 detachable pieces that are sure to make Christmas bonding with your pup extra fun. ',
	],
	bulletPoints: [
		"Hook and loop (aka 'Velcro') pockets allow limbs to be ripped off by the dog, and re-attached by the owner, for hours of continuous play",
		"Lined with strong mesh on the inside to provide extra durability and support",
		"Secure stitching allows toys to be pulled, tugged, and dismembered repeatedly",
		"Perfect for an interactive game of fetch, or tug",
		"The squeaker is secured inside a special pocket, preventing it from sliding around the toy during play",
		"Super soft, high-quality plush makes for a great comfort toy, or a cuddle buddy once the play is done",
	],
	firehoseBulletPoints: [
		"Hook and loop (aka 'Velcro') pockets allow appendages to be ripped off by the dog, and re-attached by the owner, for hours of continuous play",
		"Sturdy canvas material (aka firehose material) for extra durability and support",
		"Secure stitching allows toys to be pulled, tugged, and dismembered repeatedly",
		"Perfect for an interactive game of fetch, or tug",
		"The squeaker is secured inside of a special pocket, preventing it from sliding around the toy during play",
		"Super-stuffed insides make it a great comfort toy, or a cuddle buddy once the play is done",
	],
	perks: [
		"Removable Squeaker",
		"Machine washable",
		"Tearribles are voluntarily tested to meet or exceed even children's toys standards",
		"No Stuffing in the main body",
	],
	sizeDescription: {
		tiny: '5" tall, with detachable legs, eyes, and tail. ',
		medium: '9" tall, with detachable legs, arms, ears, and tail. ',
		xl: '14" tall, with detachable legs, arms, ears, and tail. ',
		maximus:
			'17" tall, with detachable legs, arms, ears, and head. Crinkle paper legs. A back pocket large enough to fit a tennis ball (or treats).',
		virus: `10” in diameter, with 16 detachable tentacles. No squeaker option only.`,
		candycane:
			'5" tall, with detachable legs, eyes, and tail. Christmas edition of our Tearrible Tiny.',
		gingerbread: '10" tall, with detachable head, legs, arms, and tail.',
		angel: '14" tall, with 24" wing span. Detachable head, halo, wings, and tail. "Crinkle paper" inside wings for extra fun.',
		stingray:
			'14" tall, 3 detachable fins, 1 detachable tail, and 4 removable organs.',
		octopus:
			'14" in circumference, with 8 detachable tentacles, and 2 detachable ears.',
		"hammerhead:shark":
			'14" long, 3 detachable fins, 1 detachable tail. Stationary squeaker.',
		"venus:flytrap":
			'12" tall medium, and 16" tall large with detachable legs, arms, head, tail and ears. Removable squeaker and internal organs.',
	},
	secondaryHeadline: {
		tiny: "Perfect for small dogs!",
		medium: "Perfect for small and medium dogs!",
		xl: "Perfect for medium and large dogs!",
		stingray: (
			<>
				Perfect for medium and large dogs!
				<br />
				Removable/optional squeaker.
			</>
		),
		octopus: "Perfect for medium and large dogs!",
		"hammerhead:shark": "Perfect for medium and large dogs!",
		"christmas:tree": "Perfect for all dogs!",
	},
};
